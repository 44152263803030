import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authSlice";
import adminReducer from "./reducers/adminSlice";
import jobListingsReducer from "./reducers/jobListingSlice";
import singleJobListingReducer from "./reducers/singleJobListingSlice";
import newsAndEventsReducer from "./reducers/newsAndEventSlice";
import singleNewsAndEventReducer from "./reducers/singleNewsAndEventSlice";
import newslettersReducer from "./reducers/newsletterSlice";
import singleNewsletterReducer from "./reducers/singleNewsletterSlice";
import subscriptionsReducer from "./reducers/subscriptionSlice";
import responsesReducer from "./reducers/responseSlice";
import approvalsReducer from "./reducers/approvalSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    admin: adminReducer,
    jobListings: jobListingsReducer,
    singleJobListing: singleJobListingReducer,
    newsAndEvents: newsAndEventsReducer,
    singleNewsAndEvent: singleNewsAndEventReducer,
    newsletters: newslettersReducer,
    singleNewsletter: singleNewsletterReducer,
    subscriptions: subscriptionsReducer,
    responses: responsesReducer,
    approvals: approvalsReducer,
  },
});
