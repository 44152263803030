import React, { useEffect } from "react";
import styled from "styled-components";
const TableFooter = ({ range, setPage, page, slice }) => {
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);
  return (
    <TableFooterContainer>
      {range.map((el, index) => (
        <TableFooterButton
          active={page === el ? true : false}
          key={index}
          onClick={() => setPage(el)}
        >
          {el}
        </TableFooterButton>
      ))}
    </TableFooterContainer>
  );
};

export default TableFooter;

const TableFooterContainer = styled.div`
  background-color: transparent;
  padding: 8px 0px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #2c3e50;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableFooterButton = styled.button`
  border: none;
  font-size: ${(props) => (props.active ? "16px" : "14px")};
  font-weight: ${(props) => (props.active ? "800" : "700")};
  padding: 3px 7px;
  border-radius: 7px;
  margin: 0 4px;
  color: #013a4f;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#95f400" : "transparent")};
`;
