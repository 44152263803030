import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import PasswordResetPage from "./Pages/PasswordResetPage";
import ApprovalsPage from "./Pages/Approvals/Approvals";
import CareersPage from "./Pages/Careers/Careers";
import NewsAndEventsPage from "./Pages/NewsAndEvents/NewsAndEvents";
import NewslettersPage from "./Pages/Newsletters/Newsletters";
import ResponsesPage from "./Pages/Responses/Responses";
import SettingsPage from "./Pages/Settings/Settings";
import UsersPage from "./Pages/Settings/Users";
import NewJobPage from "./Pages/Careers/NewJob";
import EditJobPage from "./Pages/Careers/EditJob";
import NewEventPage from "./Pages/NewsAndEvents/NewEvent";
import EditEventPage from "./Pages/NewsAndEvents/EditEvent";
import EditNewslettersPage from "./Pages/Newsletters/EditNewsletter";
import NewNewsletterPage from "./Pages/Newsletters/NewNewsletter";
import ViewResponsePage from "./Pages/Responses/ViewResponse";
import React, { useEffect } from "react";
import { ProtectedRoute } from "./components/shared/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import useCurrentScreen from "./utils/useCurrentScreen";
import MobileWarningModal from "./components/shared/MobileWarningModal";
import { updateUser } from "./redux/reducers/adminSlice";
import { Helmet } from "react-helmet";
import SubscribersPage from "./Pages/Newsletters/Subscribers";

function App() {
  const { user } = useSelector((state) => state.auth);
  const userAuthLevel = user?.authLevel;
  const currentRoute = useLocation().pathname;
  const navigate = useNavigate();
  const isDesktop = useCurrentScreen();
  useEffect(() => {
    if (user !== null) {
      if (
        currentRoute.includes("/dashboard/careers") &&
        userAuthLevel === "Operations Admin"
      ) {
        navigate("/dashboard/news-and-events");
      }
      if (
        (currentRoute.includes("/dashboard/news-and-events") ||
          currentRoute.includes("/dashboard/newsletters") ||
          currentRoute.includes("/dashboard/responses")) &&
        userAuthLevel === "HR Admin"
      ) {
        navigate("/dashboard/careers");
      }
      if (
        currentRoute.includes("/dashboard/settings/user") &&
        (userAuthLevel === "HR Admin" || userAuthLevel === "Operations Admin")
      ) {
        navigate("/dashboard/settings");
      }

      if (
        currentRoute.includes("/dashboard/approvals") &&
        userAuthLevel === "HR Admin"
      ) {
        navigate("/dashboard/careers");
      }
      if (
        currentRoute.includes("/dashboard/approvals") &&
        userAuthLevel === "Operations Admin"
      ) {
        navigate("/dashboard/news-and-events");
      }
    }
  }, [currentRoute, user, userAuthLevel, navigate]);
  const dispatch = useDispatch();

  const currentTime = new Date().toLocaleString();
  useEffect(() => {
    if (user !== null) {
      setTimeout(() => {
        const timeData = {
          name: user?.name,
          profileImage: user?.profileImage,
          email: user?.email,
          password: user?.password,
          _id: user?._id,
          isAdmin: user?.isAdmin,
          resetLink: user?.resetLink,
          username: user?.username,
          authLevel: user?.authLevel,
          position: user?.position,
          createdAt: user?.createdAt,
          updatedAt: user?.updatedAt,
          lastActive: currentTime,
          __v: user?.__v,
          accessToken: user?.accessToken,
        };
        dispatch(updateUser(timeData));
      }, 3000);
    }
  }, [currentTime, dispatch, user]);

  return (
    <Container>
      <Helmet>
        <title>ALFT Admin Panel</title>
      </Helmet>

      <Routes>
        <Route
          path="/"
          element={
            user ? (
              user.authLevel === "Super Admin" ? (
                <Navigate to="/dashboard/approvals" />
              ) : user.authLevel === "HR Admin" ? (
                <Navigate to="/dashboard/careers" />
              ) : (
                <Navigate to="/dashboard/news-and-events" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/login"
          element={
            user ? (
              user.authLevel === "Super Admin" ? (
                <Navigate to="/dashboard/approvals" />
              ) : user.authLevel === "HR Admin" ? (
                <Navigate to="/dashboard/careers" />
              ) : (
                <Navigate to="/dashboard/news-and-events" />
              )
            ) : (
              <LoginPage />
            )
          }
        />
        <Route path="/password-reset" element={<PasswordResetPage />} />
        <Route
          path="/dashboard/approvals"
          element={
            <ProtectedRoute>
              <ApprovalsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/careers"
          element={
            <ProtectedRoute>
              <CareersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/news-and-events"
          element={
            <ProtectedRoute>
              <NewsAndEventsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/newsletters"
          element={
            <ProtectedRoute>
              <NewslettersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/responses"
          element={
            <ProtectedRoute>
              <ResponsesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/settings"
          element={
            <ProtectedRoute>
              <SettingsPage userAuthLevel={userAuthLevel} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/settings/users"
          element={
            <ProtectedRoute>
              <UsersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/careers/new"
          element={
            <ProtectedRoute>
              <NewJobPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/careers/:id"
          element={
            <ProtectedRoute>
              <EditJobPage userAuthLevel={userAuthLevel} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/news-and-events/new"
          element={
            <ProtectedRoute>
              <NewEventPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/news-and-events/:id"
          element={
            <ProtectedRoute>
              <EditEventPage userAuthLevel={userAuthLevel} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/newsletters/new"
          element={
            <ProtectedRoute>
              <NewNewsletterPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/newsletters/:id"
          element={
            <ProtectedRoute>
              <EditNewslettersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/newsletters/subscribers"
          element={
            <ProtectedRoute>
              <SubscribersPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard/responses/:id"
          element={
            <ProtectedRoute>
              <ViewResponsePage userAuthLevel={userAuthLevel} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/approvals/careers/:id"
          element={
            user?.authLevel === "Super Admin" ? (
              <EditJobPage userAuthLevel={userAuthLevel} />
            ) : user?.authLevel === "HR Admin" ? (
              <Navigate to="/dashboard/careers" />
            ) : (
              <Navigate to="/dashboard/news-and-events" />
            )
          }
        />
        <Route
          path="/dashboard/approvals/news-and-events/:id"
          element={
            user?.authLevel === "Super Admin" ? (
              <EditEventPage userAuthLevel={userAuthLevel} />
            ) : user?.authLevel === "HR Admin" ? (
              <Navigate to="/dashboard/careers" />
            ) : (
              <Navigate to="/dashboard/news-and-events" />
            )
          }
        />
        <Route
          path="/dashboard/approvals/newsletters/:id"
          element={
            user?.authLevel === "Super Admin" ? (
              <EditNewslettersPage userAuthLevel={userAuthLevel} />
            ) : user?.authLevel === "HR Admin" ? (
              <Navigate to="/dashboard/careers" />
            ) : (
              <Navigate to="/dashboard/news-and-events" />
            )
          }
        />
      </Routes>
      <ToastContainer
        progressClassName="toastProgress"
        bodyClassName="toastBody"
      />
      {!isDesktop && <MobileWarningModal opened={true} />}
    </Container>
  );
}

export default App;

const Container = styled.div``;
