import axios from "axios";

const newsletterUrl = process.env.REACT_APP_SERVER_NEWSLETTERS_URL;

// Create new newsletter
const createPost = async (newsletterData, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.post(newsletterUrl, newsletterData, config);

  return response.data;
};

// Get single newsletter
const getPost = async (newsletterId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${newsletterUrl}/${newsletterId}`, config);

  return response.data;
};

// Get all newsletters
const getAllPosts = async (token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.get(newsletterUrl, config);

  return response.data;
};

// Update newsletter
const updatePost = async (newsletterData, newsletterId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
      "content-type": "application/json",
    },
  };
  console.log(newsletterData);
  const response = await axios.put(
    `${newsletterUrl}/${newsletterId}`,
    newsletterData,
    config
  );

  return response.data;
};

// Delete newsletter
const deletePost = async (newsletterId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(
    `${newsletterUrl}/${newsletterId}`,
    config
  );

  return response.data;
};

const newsletterService = {
  createPost,
  getPost,
  getAllPosts,
  updatePost,
  deletePost,
};

export default newsletterService;
