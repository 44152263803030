import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Modal from "../shared/Modal";
import Dropzone from "../shared/Dropzone";
import { PrimaryButton } from "../shared/Buttons";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { importContacts } from "../../redux/reducers/subscriptionSlice";
import { errorToast, successToast } from "../shared/Toasts";
import { Spinner } from "../shared/Spinner";

const ImportEmailsModal = ({ onClose, opened }) => {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files]
  );

  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.subscriptions
  );

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      maxFiles: 1,
      accept: {
        "text/csv": [".csv"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      onDrop,
    });

  const handleImportContacts = (e) => {
    e.preventDefault();
    setLoading(true);
    const importedData = new FormData();
    importedData.append("file", files[0]);
    dispatch(importContacts(importedData));
    setTimeout(() => {
      if (!isLoading) {
        onClose();
      }
    }, 2000);

    setTimeout(() => {
      if (isError) {
        setLoading(false);
        errorToast(message);
      }

      if (isSuccess) {
        setLoading(false);
        successToast("", "Successfully Imported Contacts From File");
        setTimeout(() => {
          navigate(0);
        }, 2501);
      }
    }, 2000);
  };

  return (
    <Modal
      onClose={() => {
        onClose();
        setFiles([]);
      }}
      backgroundWhite={true}
      opened={opened}
    >
      <ImportEmailsModalContent onSubmit={handleImportContacts}>
        <Title>Import</Title>
        <Title style={{ marginTop: "15px", fontSize: "17px", fontWeight: 600 }}>
          Upload an excel sheet/CSV file with Emails
        </Title>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Dropzone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isDragActive={isDragActive}
            styles={{ width: "90%" }}
            acceptedFiles={files}
            removeFileAction={removeFile}
          />
        </div>
        <PrimaryButton style={{ marginTop: "30px" }} type="submit">
          {loading ? (
            <>
              <span>Importing</span>
              <Spinner />
            </>
          ) : (
            <span>Import</span>
          )}
        </PrimaryButton>
      </ImportEmailsModalContent>
    </Modal>
  );
};

export default ImportEmailsModal;

const ImportEmailsModalContent = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 30px;
  width: 100%;
`;

const Title = styled.span`
  font-size: 22px;
  font-weight: 800;
  margin-top: 25px;
  padding-bottom: 15px;
  color: #013a4f;
  text-align: center;
`;
