import React, { useState } from "react";
import styled from "styled-components";
import { TbTrash, TbEdit } from "react-icons/tb";
import {
  TableActionButton,
  TableActionToggle,
  TableStatusLabel,
} from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { Popout } from "../../components";
import SkeletonListingTable from "./SkeletonListingTable";
import format from "date-fns/format";
import TableFooter from "./TableFooter";
import useTable from "../../utils/useTablePagination";

const ListingsTable = ({
  heads,
  tableData,
  type,
  loading,
  deleteItem,
  setDeleteItem,
  primaryButtonAction,
  loadingDelete,
  activate,
  setActivate,
  setActiveItem,
  setUniqueUser,
  setEditMode,
}) => {
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  const currentPath = useLocation().pathname;
  const { slice, range } = useTable(tableData, page, 6);

  return (
    <>
      {loading ? (
        <SkeletonListingTable />
      ) : (
        <>
          {tableData?.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                minHeight: "650px",
              }}
            >
              <TableContainer style={{ paddingBottom: "15px" }}>
                <TableHead>
                  <TableHeadRow>
                    {heads?.map((head, index) => {
                      return (
                        <TableHeadItem
                          key={index}
                          item={head}
                          center={
                            (head === "status" ? true : false) ||
                            (head.includes("action") ? true : false)
                          }
                        >
                          {head}
                        </TableHeadItem>
                      );
                    })}
                  </TableHeadRow>
                </TableHead>

                <TableBody>
                  {slice?.map((d, index) => (
                    <TableRow
                      clickable={type === "responses" ? true : false}
                      onClick={
                        type === "responses"
                          ? () => navigate(`${currentPath}/${d?._id}`)
                          : false
                      }
                      key={index}
                    >
                      {type === "careers" && (
                        <>
                          <TableDescription item={d?.jobRole}>
                            {d?.jobRole}
                          </TableDescription>
                          <TableDescription item={d?.jobType}>
                            {d?.jobType}
                          </TableDescription>
                          <TableDescription item={d?.status} center>
                            <TableStatusLabel
                              active={d?.status === "approved" ? true : false}
                              inactive={
                                d?.status === "not approved" ? true : false
                              }
                              pending={d?.status === "pending" ? true : false}
                            >
                              {d?.status}
                            </TableStatusLabel>
                          </TableDescription>
                        </>
                      )}
                      {type === "events" && (
                        <>
                          <TableDescription item={d?.createdAt}>
                            {format(new Date(d?.createdAt), "d-MM-yyyy ")}
                          </TableDescription>
                          <TableDescription item={d?.item}>
                            {d?.title}
                          </TableDescription>
                          <TableDescription item={d?.status} center>
                            <TableStatusLabel
                              active={d?.status === "approved" ? true : false}
                              inactive={
                                d?.status === "not approved" ? true : false
                              }
                              pending={d?.status === "pending" ? true : false}
                            >
                              {d?.status}
                            </TableStatusLabel>
                          </TableDescription>
                        </>
                      )}
                      {type === "newsletters" && (
                        <>
                          <TableDescription item={d?.createdAt}>
                            {format(new Date(d?.createdAt), "d-MM-yyyy ")}
                          </TableDescription>
                          <TableDescription item={d?.title}>
                            {d?.title}
                          </TableDescription>
                          <TableDescription item={d?.status} center>
                            <TableStatusLabel
                              approved={d?.status === "approved" ? true : false}
                              notApproved={
                                d?.status === "not approved" ? true : false
                              }
                              pending={d?.status === "pending" ? true : false}
                            >
                              {d?.status}
                            </TableStatusLabel>
                          </TableDescription>
                        </>
                      )}
                      {type === "responses" && (
                        <>
                          <TableDescription item={d?.createdAt}>
                            {format(new Date(d?.createdAt), "d-MM-yyyy ")}
                          </TableDescription>
                          <TableDescription item={d?.name}>
                            {d?.name}
                          </TableDescription>
                          <TableDescription item={d?.email}>
                            {d?.email}
                          </TableDescription>
                          <TableDescription item={d?.status} center>
                            <TableStatusLabel
                              opened={d?.status === "opened" ? true : false}
                              unopened={d?.status === "unopened" ? true : false}
                              responded={
                                d?.status === "responded" ? true : false
                              }
                            >
                              {d?.status}
                            </TableStatusLabel>
                          </TableDescription>
                        </>
                      )}
                      {type === "users" && (
                        <>
                          <TableDescription item={d?.name}>
                            {d?.name}
                          </TableDescription>
                          <TableDescription item={d?.position}>
                            {d?.position}
                          </TableDescription>
                          <TableDescription item={d?.lastActive}>
                            {d?.lastActive}
                          </TableDescription>
                        </>
                      )}

                      {currentPath !== "/dashboard/responses" &&
                        currentPath !== "/dashboard/settings/users" && (
                          <TableDescription center>
                            <TableActionButton
                              onClick={() => setDeleteItem(d?._id)}
                            >
                              <TbTrash size={20} />
                            </TableActionButton>
                            <TableActionButton
                              onClick={() =>
                                navigate(`${currentPath}/${d?._id}`)
                              }
                            >
                              <TbEdit size={20} />
                            </TableActionButton>
                            {currentPath === "/dashboard/careers" && (
                              <>
                                {d?.isActive ? (
                                  <TableActionToggle
                                    handleOnSlide={() => {
                                      setActiveItem(d?._id);
                                    }}
                                    checked={true}
                                  />
                                ) : (
                                  <TableActionToggle
                                    handleOnSlide={() => {
                                      setActiveItem(d?._id);
                                    }}
                                    checked={false}
                                  />
                                )}
                              </>
                            )}
                          </TableDescription>
                        )}
                      {currentPath === "/dashboard/settings/users" && (
                        <TableDescription
                          style={{
                            width: "100%",
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableStatusLabel
                            remove={true}
                            onClick={() => setDeleteItem(d?._id)}
                            clickable
                          >
                            remove user
                          </TableStatusLabel>
                          <TableActionButton
                            onClick={() => {
                              setUniqueUser(d);
                              setEditMode(true);
                            }}
                            style={{ marginLeft: "8px", marginTop: "-1px" }}
                          >
                            <TbEdit size={20} />
                          </TableActionButton>
                        </TableDescription>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </TableContainer>
              {range?.length > 1 && (
                <TableFooter
                  range={range}
                  slice={slice}
                  setPage={setPage}
                  page={page}
                />
              )}
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TableContainer>
                <TableHead>
                  <TableHeadRow>
                    {heads?.map((head, index) => {
                      return (
                        <TableHeadItem
                          key={index}
                          item={head}
                          center={
                            (head === "status" ? true : false) ||
                            (head.includes("action") ? true : false)
                          }
                        >
                          {head}
                        </TableHeadItem>
                      );
                    })}
                  </TableHeadRow>
                </TableHead>
              </TableContainer>
              <NoListingsDescription>
                No{" "}
                {type === "careers"
                  ? "Job Listings"
                  : type === "events"
                  ? "News And Events"
                  : type === "newsletters"
                  ? "Newsletters"
                  : type === "responses"
                  ? "Responses"
                  : "Users"}
              </NoListingsDescription>
            </div>
          )}
        </>
      )}

      <Popout
        onClose={() => setDeleteItem(null)}
        boldText="ARE YOU SURE?"
        text="You will not be able to undo this action if you proceed!"
        primaryButtonText="Delete"
        primaryLoadingButtonText="Deleting"
        secondaryButtonText="Cancel"
        opened={deleteItem !== null}
        primaryButtonAction={primaryButtonAction}
        loadingAction={loadingDelete}
      />
    </>
  );
};

export default ListingsTable;

const TableContainer = styled.table`
  width: 100%;
  border-radius: 50px;
  border-spacing: 0;
`;

const TableHead = styled.thead`
  background-color: #013a4f;
  color: rgba(255, 255, 255, 0.7);
  width: 100%;
  border-radius: 50px;
`;

const TableBody = styled.tbody`
  background-color: transparent;
  width: 100%;
`;

const TableHeadRow = styled.tr`
  background-color: transparent;
  width: 100%;
  height: 60px;
  text-align: center;
`;

const TableRow = styled.tr`
  background-color: transparent;
  width: 100%;
  height: 45px;
  border-bottom: 1px solid gray;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: ${(props) =>
      props.clickable ? "rgba(194, 194, 194, 0.15)" : "transparent"};
  }
`;

const TableHeadItem = styled.th`
  font-size: 14px;
  text-align: left;
  padding: 0 35px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: ${(props) => (props.center ? "center" : "left")}};
`;

const TableDescription = styled.td`
  font-size: 15px;
  padding: 30px 35px;
  font-weight: 500;
  border-bottom: 0.05px solid rgba(194, 194, 194, 0.4);
  max-width: 13rem;
  text-align: ${(props) => (props.center ? "center" : "left")}};
`;

const NoListingsDescription = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding: 30px 0;
  font-weight: 500;
  border-bottom: 0.05px solid rgba(194, 194, 194, 0.4);
  text-align: center;
`;
