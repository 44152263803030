import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import newsAndEventService from "../services/newsAndEventService";

const initialState = {
  newsAndEvents: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Edit news and event
export const updatePost = createAsyncThunk(
  `singleNewsAndEvent/edit`,
  async (newsAndEventData, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.accessToken;
    const newsAndEventDataFormData = new FormData();
    newsAndEventDataFormData.append("title", newsAndEventData.title);
    newsAndEventDataFormData.append("authorName", newsAndEventData.authorName);
    newsAndEventDataFormData.append("newsItem", newsAndEventData.newsItem);
    newsAndEventDataFormData.append("coverImage", newsAndEventData.coverImage);
    newsAndEventDataFormData.append("status", newsAndEventData.approvalStatus);
    newsAndEventDataFormData.append("category", newsAndEventData.category);
    newsAndEventDataFormData.append("userName", newsAndEventData.userName);

    try {
      return await newsAndEventService.updatePost(
        newsAndEventDataFormData,
        newsAndEventData.newsAndEventId,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete a news and event
export const deletePost = createAsyncThunk(
  "singleNewsAndEvent/delete",
  async (newsAndEventId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await newsAndEventService.deletePost(newsAndEventId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const singleNewsAndEventSlice = createSlice({
  name: "singleNewsAndEvent",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.jobListings = action.payload;
      })
      .addCase(updatePost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deletePost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(deletePost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = singleNewsAndEventSlice.actions;
export default singleNewsAndEventSlice.reducer;
