import styled from "styled-components";

export const PageFormContainer = styled.div`
  width: 100%;
  padding: 10px 35px;
  postion: relative;
  padding-right: 55px;
`;

export const PageForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

export const PageFormInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

export const PageFormInlineInputs = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageFormTextEditorContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;
