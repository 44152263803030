import React, { useState } from "react";
import {
  errorToast,
  DashboardLayout,
  NewsAndEventsForm,
  successToast,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createPost } from "../../redux/reducers/newsAndEventSlice";

const EditEventPage = () => {
  const [title, setTitle] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [newsItem, setNewsItem] = useState("");
  const [coverImage, setCoverImage] = useState("string");
  const [category, setCategory] = useState("News");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.newsAndEvents
  );
  const { user } = useSelector((state) => state.auth);

  const handleCreateNewsAndEvent = (e) => {
    e.preventDefault();

    const newsAndEventDataFormData = new FormData();
    newsAndEventDataFormData.append("title", title);
    newsAndEventDataFormData.append("authorName", authorName);
    newsAndEventDataFormData.append("newsItem", newsItem);
    newsAndEventDataFormData.append("coverImage", coverImage);
    newsAndEventDataFormData.append("category", category);
    newsAndEventDataFormData.append("userName", user?.name);

    dispatch(createPost(newsAndEventDataFormData));
    if (!isLoading) {
      setTimeout(() => {
        if (isError) {
          errorToast(message);
        }

        if (isSuccess) {
          successToast("created");
          setTimeout(() => {
            navigate("/dashboard/news-and-events");
          }, 3000);
        }
      }, 1500);
    }
  };
  return (
    <DashboardLayout title="Add New Event">
      <NewsAndEventsForm
        handleNewsAndEventFormSubmit={handleCreateNewsAndEvent}
        title={title}
        setTitle={setTitle}
        authorName={authorName}
        setAuthorName={setAuthorName}
        category={category}
        setCategory={setCategory}
        newsItem={newsItem}
        setNewsItem={setNewsItem}
        coverImage={coverImage}
        setCoverImage={setCoverImage}
        loading={isLoading}
      />
    </DashboardLayout>
  );
};

export default EditEventPage;
