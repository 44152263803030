import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import newsletterService from "../services/newsletterService";

const initialState = {
  newsletters: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Edit news and event
export const updatePost = createAsyncThunk(
  `singleNewsletter/edit`,
  async (newsletterData, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.accessToken;
    const data = {
      name: thunkAPI.getState().auth.user.name,
      title: newsletterData.title,
      campaignItem: newsletterData.campaignItem,
      status: newsletterData.approvalStatus,
      userName: newsletterData.userName,
    };

    try {
      return await newsletterService.updatePost(
        data,
        newsletterData.newsletterId,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete a news and event
export const deleteNewsletter = createAsyncThunk(
  "singleNewsletter/delete",
  async (newsletterId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await newsletterService.deletePost(newsletterId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const singleNewsAndEventSlice = createSlice({
  name: "singleNewsletter",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.jobListings = action.payload;
      })
      .addCase(updatePost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteNewsletter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteNewsletter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(deleteNewsletter.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = singleNewsAndEventSlice.actions;
export default singleNewsAndEventSlice.reducer;
