import axios from "axios";

const subscriptionUrl = process.env.REACT_APP_SERVER_SUBSCRIPTIONS_URL;

// Get all subscriptions
const getAllSubscriptions = async (token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.get(subscriptionUrl, config);

  return response.data;
};

const addNewContact = async (contactData, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.post(subscriptionUrl, contactData, config);

  return response.data;
};

const updateContact = async (contactData, contactId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };
  console.log(contactData, contactId);
  const response = await axios.put(
    `${subscriptionUrl}/${contactId}`,
    contactData,
    config
  );

  return response.data;
};

const importContacts = async (data, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${subscriptionUrl}/import`, data, config);

  return response.data;
};

const deleteContact = async (contactId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(
    `${subscriptionUrl}/${contactId}`,
    config
  );

  return response.data;
};

const subscriptionService = {
  getAllSubscriptions,
  addNewContact,
  updateContact,
  importContacts,
  deleteContact,
};

export default subscriptionService;
