import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsImage } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import styled from "styled-components";

const StyledDropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  width: 100%;
`;

const Dropzone = ({
  styles,
  getRootProps,
  getInputProps,
  isDragActive,
  acceptedFiles,
  removeFileAction,
}) => {
  return (
    <>
      {acceptedFiles.length > 0 ? (
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          {acceptedFiles.map((f) => (
            <div
              style={{
                width: "100%",
                marginTop: "8px",
                backgroundColor: "white",
                border: "0.5px solid rgba(194, 194, 194, 0.4)",
                padding: "25px 21px 25px 21px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={{ fontWeight: 600 }}>{f.name}</p>

              <RemoveFileButton
                style={{ marginLeft: "10px" }}
                onClick={removeFileAction(f)}
              />
            </div>
          ))}
        </div>
      ) : (
        <StyledDropzoneContainer
          {...getRootProps({ className: "dropzone" })}
          style={{
            backgroundImage:
              acceptedFiles.length > 0
                ? ""
                : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='black' stroke-width='3' opacity='0.5' stroke-dasharray='6%2c 17' stroke-dashoffset='73' stroke-linecap='square'/%3e%3c/svg%3e")`,
            padding: acceptedFiles.length > 0 ? "0" : "34px 27px 30px 27px",
            ...styles,
          }}
        >
          <input {...getInputProps()} />
          <BsImage size={40} style={{ color: "#95F400" }} />

          <div sx={{ width: "100%", marginTop: "8px" }}>
            {isDragActive ? (
              <p style={{ fontWeight: 600, opacity: "0.7" }}>
                Release to drop the files here
              </p>
            ) : (
              <>
                <p style={{ fontWeight: 600, opacity: "0.7" }}>
                  Drop your file here or ,
                  <span style={{ color: "#092631", cursor: "pointer" }}>
                    browse
                  </span>
                  <br />
                  <small style={{ opacity: "0.5", marginTop: "4px" }}>
                    Supports .xlsx, .xls, .csv
                  </small>
                </p>
              </>
            )}
          </div>
        </StyledDropzoneContainer>
      )}
    </>
  );
};

export default Dropzone;

const RemoveFileButton = styled(IoCloseSharp)`
  background-color: rgba(1, 58, 79, 0.9);
  color: white;
  padding: 3px;
  border-radius: 20%;
  cursor: pointer;
  &:hover {
    background-color: rgba(1, 58, 79, 1);
  }
`;
