import React from "react";
import {
  FormButtonsContainer,
  PrimaryButton,
  SecondaryButton,
} from "../shared/Buttons";
import FormInput from "../shared/FormInput";
import PostApproval from "../shared/PostApproval";
import TextEditor from "../shared/TextEditor";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../shared/Spinner";
import { HelperText, InputLabel } from "../shared/Texts";
import {
  PageForm,
  PageFormContainer,
  PageFormInlineInputs,
  PageFormInputContainer,
  PageFormTextEditorContainer,
} from "../shared/PageFormComponents";

const CareersForm = ({
  jobListingId,
  handleJobListingFormSubmit,
  jobRole,
  setJobRole,
  jobType,
  setJobType,
  jobRequirements,
  setJobRequirements,
  loading,
  userAuthLevel,
  admin,
  status,
  approvalStatus,
  setApprovalStatus,
  department,
  setDepartment,
}) => {
  const navigate = useNavigate();

  return (
    <PageFormContainer>
      <PageForm onSubmit={handleJobListingFormSubmit}>
        <PageFormInlineInputs>
          <PageFormInputContainer>
            <FormInput
              text="Job Role"
              type="text"
              value={jobRole}
              name="jobRole"
              handleOnChange={(e) => setJobRole(e.target.value)}
              plain={true}
              short={true}
              disabled={admin}
              helperText="Type the name of the job vacancy"
            />
          </PageFormInputContainer>
          <PageFormInputContainer>
            <FormInput
              text="Job Type"
              inputType="dropdown"
              plain={true}
              short={true}
              name="jobType"
              disabled={admin}
              dropdownOptions={[
                "Full-Time",
                "Part-Time",
                "Internship",
                "Contract",
              ]}
              dropdownValue={jobType}
              handleOnChange={(e) => setJobType(e.target.value)}
              helperText="Select the type of the job"
            />
          </PageFormInputContainer>
        </PageFormInlineInputs>
        <PageFormInputContainer style={{ width: "50%" }}>
          <FormInput
            text="Department"
            type="text"
            value={department}
            name="department"
            handleOnChange={(e) => setDepartment(e.target.value)}
            plain={true}
            short={true}
            disabled={admin}
            helperText="Mention the department of the position"
          />
        </PageFormInputContainer>
        <PageFormTextEditorContainer>
          <InputLabel>Requirements</InputLabel>
          <HelperText>
            Type the requirements you’re looking for in your potential
            candidates
          </HelperText>
          <TextEditor
            textData={jobRequirements}
            setTextData={setJobRequirements}
            text="jobRequirements"
            disabled={admin}
          />
        </PageFormTextEditorContainer>
        {jobListingId && admin && (
          <FormButtonsContainer left>
            <PostApproval
              approvalStatus={approvalStatus}
              setApprovalStatus={setApprovalStatus}
            />
          </FormButtonsContainer>
        )}

        <FormButtonsContainer right>
          <SecondaryButton
            onClick={() =>
              admin
                ? navigate("/dashboard/approvals")
                : navigate("/dashboard/careers")
            }
            type="button"
            marginRight
          >
            Cancel
          </SecondaryButton>
          {jobListingId && (
            <>
              {admin ? (
                approvalStatus === "approved" ? (
                  <PrimaryButton type="submit">
                    {loading ? (
                      <>
                        <span>Posting Item</span>
                        <Spinner />
                      </>
                    ) : (
                      <span>Post Item</span>
                    )}
                  </PrimaryButton>
                ) : approvalStatus === "not approved" ? (
                  <PrimaryButton type="submit">
                    {loading ? (
                      <>
                        <span>Discarding Item</span>
                        <Spinner />
                      </>
                    ) : (
                      <span>Discard Item</span>
                    )}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton type="submit">
                    {loading ? (
                      <>
                        <span>Saving Changes</span>
                        <Spinner />
                      </>
                    ) : (
                      <span>Save Changes</span>
                    )}
                  </PrimaryButton>
                )
              ) : (
                <PrimaryButton type="submit">
                  {loading ? (
                    <>
                      <span>Saving Changes</span>
                      <Spinner />
                    </>
                  ) : (
                    <span>Save Changes</span>
                  )}
                </PrimaryButton>
              )}
            </>
          )}
          {!jobListingId && (
            <PrimaryButton type="submit">
              {loading ? (
                <>
                  <span>Adding Vacancy</span>
                  <Spinner />
                </>
              ) : (
                <span>Add Vacancy</span>
              )}
            </PrimaryButton>
          )}
        </FormButtonsContainer>
      </PageForm>
    </PageFormContainer>
  );
};

export default CareersForm;
