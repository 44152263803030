import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import newsAndEventService from "../services/newsAndEventService";

const initialState = {
  newsAndEvents: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create new news and event
export const createPost = createAsyncThunk(
  "newsAndEvents/create",
  async (newsAndEventData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await newsAndEventService.createPost(newsAndEventData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get single news and event
export const getPost = createAsyncThunk(
  "newsAndEvents/single",
  async (jobListingId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await newsAndEventService.getPost(jobListingId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all news and events
export const getAllPosts = createAsyncThunk(
  "newsAndEvents",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      const newsAndEvents = await newsAndEventService.getAllPosts(token);
      const sortedNewsAndEvents = newsAndEvents.sort((a, b) =>
        b.createdAt.localeCompare(a.createdAt)
      );
      return sortedNewsAndEvents;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const newsAndEventSlice = createSlice({
  name: "newsAndEvents",
  initialState,
  reducers: {
    reset: (state) => initialState,
    removeNewsAndEvent: (state, action) => {
      state.newsAndEvents = state.newsAndEvents.filter(
        (n) => n._id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.newsAndEvents.push(action.payload);
      })
      .addCase(createPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.newsAndEvents = action.payload;
      })
      .addCase(getPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllPosts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.newsAndEvents = action.payload;
      })
      .addCase(getAllPosts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, removeNewsAndEvent } = newsAndEventSlice.actions;
export default newsAndEventSlice.reducer;
