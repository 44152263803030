import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subscriptionService from "../services/subscriptionService";

const initialState = {
  subscriptions: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get all subscriptions
export const getAllSubscriptions = createAsyncThunk(
  "subscription",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      const response = await subscriptionService.getAllSubscriptions(token);
      return response.sort((a, b) =>
        new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
          ? -1
          : 1
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addNewContact = createAsyncThunk(
  "subscription/create",
  async (contactData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await subscriptionService.addNewContact(contactData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateContact = createAsyncThunk(
  "subscription/update",
  async (contactData, thunkAPI) => {
    const data = {
      email: contactData.email,
      name: contactData.name,
    };
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await subscriptionService.updateContact(
        data,
        contactData.contactId,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const importContacts = createAsyncThunk(
  "subscription/import",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await subscriptionService.importContacts(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteContact = createAsyncThunk(
  "subscription/delete",
  async (contactId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await subscriptionService.deleteContact(contactId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    reset: (state) => initialState,
    removeContact: (state, action) => {
      state.subscriptions = state.subscriptions.filter(
        (n) => n._id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubscriptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSubscriptions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscriptions = action.payload;
      })
      .addCase(getAllSubscriptions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addNewContact.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscriptions.push(action.payload);
      })
      .addCase(addNewContact.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateContact.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscriptions = state.subscriptions.map((j) => {
          if (j._id === action.payload._id) {
            return {
              ...j,
              name: action.payload.name,
              email: action.payload.email,
            };
          }
          return j;
        });
      })
      .addCase(updateContact.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteContact.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(deleteContact.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(importContacts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(importContacts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(importContacts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, removeContact } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
