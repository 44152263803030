import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import newsletterService from "../services/newsletterService";

const initialState = {
  newsletters: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create new news and event
export const createPost = createAsyncThunk(
  "newsletter/create",
  async (newsletterData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await newsletterService.createPost(newsletterData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get single news and event
export const getPost = createAsyncThunk(
  "newsletter/single",
  async (newsletterId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await newsletterService.getPost(newsletterId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all news and events
export const getAllNewsletters = createAsyncThunk(
  "newsletter",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      const newsletters = await newsletterService.getAllPosts(token);
      const sortedNewsletters = newsletters.sort((a, b) =>
        b.createdAt.localeCompare(a.createdAt)
      );
      return sortedNewsletters;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const newsletterSlice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {
    reset: (state) => initialState,
    removeNewsletter: (state, action) => {
      state.newsletters = state.newsletters.filter(
        (n) => n._id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.newsletters.push(action.payload);
      })
      .addCase(createPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.newsletters = action.payload;
      })
      .addCase(getPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllNewsletters.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllNewsletters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.newsletters = action.payload;
      })
      .addCase(getAllNewsletters.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, removeNewsletter } = newsletterSlice.actions;
export default newsletterSlice.reducer;
