import React, { useState, useEffect } from "react";
import {
  DashboardLayout,
  ListingsTable,
  errorToast,
  successToast,
} from "../../components";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllPosts,
  removeNewsAndEvent,
} from "../../redux/reducers/newsAndEventSlice";
import { deletePost } from "../../redux/reducers/singleNewsAndEventSlice";

const NewsAndEventsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteItem, setDeleteItem] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const heads = ["published date", "title", "status", "actions"];
  const { newsAndEvents, isLoading } = useSelector(
    (state) => state.newsAndEvents
  );
  const { isError, message } = useSelector((state) => state.singleNewsAndEvent);

  useEffect(() => {
    dispatch(getAllPosts());
  }, [dispatch]);

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(removeNewsAndEvent(deleteItem));
    dispatch(deletePost(deleteItem));
    setTimeout(() => {
      setLoadingDelete(false);
      setDeleteItem(null);
      successToast("deleted");
    }, 400);
  };
  useEffect(() => {
    if (isError) {
      errorToast(message);
    }
  }, [isError, message]);
  return (
    <DashboardLayout
      title="News & Events"
      buttons={[
        {
          name: "New Post",
          action: () => navigate("/dashboard/news-and-events/new"),
          type: "contained",
        },
      ]}
    >
      <ListingsTable
        heads={heads}
        tableData={newsAndEvents}
        type="events"
        loading={isLoading}
        deleteItem={deleteItem}
        setDeleteItem={setDeleteItem}
        loadingDelete={loadingDelete}
        primaryButtonAction={handleDelete}
      />
    </DashboardLayout>
  );
};

export default NewsAndEventsPage;
