import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jobListingService from "../services/jobListingService";

const initialState = {
  jobListings: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create new job listing
export const createJobListing = createAsyncThunk(
  "joblistings/create",
  async (jobListingData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await jobListingService.createJobListing(jobListingData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get single job listing
export const getJobListing = createAsyncThunk(
  "jobListings/single",
  async (jobListingId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await jobListingService.getJoblisting(jobListingId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all job listings
export const getAllJobListings = createAsyncThunk(
  "jobListings",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      const jobs = await jobListingService.getAllJobListings(token);
      const sortedJobs = jobs.sort((a, b) =>
        a.createdAt.localeCompare(b.createdAt)
      );
      return sortedJobs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const jobListingSlice = createSlice({
  name: "jobListing",
  initialState,
  reducers: {
    reset: (state) => initialState,
    removeJobListing: (state, action) => {
      state.jobListings = state.jobListings.filter(
        (j) => j._id !== action.payload
      );
    },
    updateStatus: (state, action) => {
      state.jobListings = state.jobListings.map((j) => {
        if (j._id === action.payload.jobListingId) {
          return { ...j, isActive: action.payload.activeStatus };
        }
        return j;
      });
    },
    updateApprovalStatus: (state, action) => {
      state.jobListings = {
        ...state.jobListings,
        status: action.payload.status,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createJobListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createJobListing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.jobListings.push(action.payload);
      })
      .addCase(createJobListing.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getJobListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getJobListing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.jobListings = action.payload;
      })
      .addCase(getJobListing.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllJobListings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllJobListings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.jobListings = action.payload;
      })
      .addCase(getAllJobListings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, removeJobListing, updateStatus, updateApprovalStatus } =
  jobListingSlice.actions;
export default jobListingSlice.reducer;
