import React, { useEffect, useState } from "react";
import {
  DashboardLayout,
  NewUserModal,
  SettingsTab,
  ListingsTable,
  errorToast,
  successToast,
} from "../../components";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getAllUsers,
  removeUser,
} from "../../redux/reducers/adminSlice";

const UsersPage = () => {
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [uniqueUser, setUniqueUser] = useState(null);

  const heads = ["user", "position", "last active", "action"];
  const dispatch = useDispatch();

  const { users, isLoading, isError, message, isSuccess } = useSelector(
    (state) => state.admin
  );
  const { user } = useSelector((state) => state.auth);

  const usersList = users.filter((u) => u._id !== user._id);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(removeUser(deleteItem));
    dispatch(deleteUser(deleteItem));
    setTimeout(() => {
      setLoadingDelete(false);
      setDeleteItem(null);
      if (isError) {
        errorToast(message);
      }

      if (isSuccess) {
        successToast("", "Removed User");
      }
    }, 1000);
  };

  return (
    <DashboardLayout
      title="Admin Settings"
      buttons={[
        {
          name: "Add User",
          action: () => setShowNewUserModal(true),
          type: "contained",
        },
      ]}
    >
      <UsersSettingsContainer>
        <SettingsTab />
        <UsersSettingsContent>
          <ListingsTable
            heads={heads}
            tableData={usersList}
            type="users"
            deleteItem={deleteItem}
            setDeleteItem={setDeleteItem}
            loading={isLoading}
            loadingDelete={loadingDelete}
            primaryButtonAction={handleDelete}
            setEditMode={setEditMode}
            setUniqueUser={setUniqueUser}
          />
        </UsersSettingsContent>
      </UsersSettingsContainer>
      <NewUserModal
        opened={showNewUserModal || uniqueUser !== null}
        onClose={() => {
          setShowNewUserModal(false);
          setEditMode(false);
          setUniqueUser(null);
        }}
        user={uniqueUser}
        editMode={editMode}
      />
    </DashboardLayout>
  );
};

export default UsersPage;

const UsersSettingsContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const UsersSettingsContent = styled.div`
  width: 100%;
  padding-top: 40px;
`;
