import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TbTrash, TbEdit } from "react-icons/tb";
import {
  DashboardLayout,
  errorToast,
  Popout,
  successToast,
} from "../../components";
import { TableActionButton, TableStatusLabel } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllApprovalListings,
  removeApprovalListing,
} from "../../redux/reducers/approvalSlice";
import SkeletonListingTable from "../../components/shared/SkeletonListingTable";
import { useLocation, useNavigate } from "react-router-dom";
import useTable from "../../utils/useTablePagination";
import TableFooter from "../../components/shared/TableFooter";
import { deleteJoblisting } from "../../redux/reducers/singleJobListingSlice";
import { deleteNewsletter } from "../../redux/reducers/singleNewsletterSlice";
import { deletePost } from "../../redux/reducers/singleNewsAndEventSlice";
import ImportEmailsModal from "../../components/newsletters/ImportEmailsModal";
import ContactModal from "../../components/newsletters/ContactModal";
import {
  deleteContact,
  getAllSubscriptions,
  removeContact,
} from "../../redux/reducers/subscriptionSlice";
import { format } from "date-fns";

const SubscribersPage = () => {
  const [importModal, setImportModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [page, setPage] = useState(1);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;
  const heads = ["subscribed date", "email", "full name", "actions"];
  const dispatch = useDispatch();

  const { subscriptions, isError, message } = useSelector(
    (state) => state.subscriptions
  );

  const { slice, range } = useTable(subscriptions, page, 6);

  useEffect(() => {
    dispatch(getAllSubscriptions());
  }, [dispatch]);

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(removeContact(deleteItem));
    dispatch(deleteContact(deleteItem));
    setTimeout(() => {
      setLoadingDelete(false);
      setDeleteItem(null);
      successToast("deleted");
    }, 400);
  };
  useEffect(() => {
    if (isError) {
      errorToast(message);
    }
  }, [isError, message]);

  return (
    <DashboardLayout
      title="Subscriber List"
      buttons={[
        {
          name: "Upload Email List",
          action: () => setImportModal(true),
          type: "outlined",
        },
        {
          name: "Create Contact",
          action: () => setContactModal(true),
          type: "contained",
        },
      ]}
    >
      {isLoading ? (
        <SkeletonListingTable />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            minHeight: "650px",
          }}
        >
          {subscriptions.length > 0 ? (
            <TableContainer style={{ paddingBottom: "2rem" }}>
              <TableHead>
                <TableHeadRow>
                  {heads.map((head, index) => {
                    return (
                      <TableHeadItem key={index} item={head}>
                        {head}
                      </TableHeadItem>
                    );
                  })}
                </TableHeadRow>
              </TableHead>
              <TableBody>
                {slice.map((d, index) => (
                  <TableRow clickable={false} key={d._id}>
                    <>
                      <TableDescription item={d.createdAt}>
                        {format(new Date(d?.createdAt), "d-MM-yyyy ")}
                      </TableDescription>
                      <TableDescription item={d.email}>
                        {d.email}
                      </TableDescription>
                      <TableDescription item={d.name} center>
                        {d.name}
                      </TableDescription>
                    </>
                    <TableDescription center>
                      <TableActionButton
                        onClick={() => {
                          setDeleteItem(d._id);
                        }}
                      >
                        <TbTrash size={20} />
                      </TableActionButton>
                      <TableActionButton
                        onClick={() => {
                          setContactModal(true);
                          setContactId(d._id);
                          setContactData(d);
                        }}
                      >
                        <TbEdit size={20} />
                      </TableActionButton>
                    </TableDescription>
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TableContainer>
                <TableHead>
                  <TableHeadRow>
                    {heads.map((head, index) => {
                      return (
                        <TableHeadItem
                          key={index}
                          item={head}
                          center={
                            (head === "status" ? true : false) ||
                            (head.includes("action") ? true : false)
                          }
                        >
                          {head}
                        </TableHeadItem>
                      );
                    })}
                  </TableHeadRow>
                </TableHead>
              </TableContainer>
              <NoListingsDescription>No Subscribers</NoListingsDescription>
            </div>
          )}
          {range.length > 1 && (
            <TableFooter
              range={range}
              slice={slice}
              setPage={setPage}
              page={page}
            />
          )}{" "}
        </div>
      )}
      <Popout
        onClose={() => setDeleteItem(false)}
        boldText="ARE YOU SURE?"
        text="You will not be able to undo this action if you proceed!"
        primaryButtonText="Delete"
        primaryLoadingButtonText="Deleting"
        secondaryButtonText="Cancel"
        opened={deleteItem}
        primaryButtonAction={handleDelete}
        loadingAction={loadingDelete}
      />
      <ImportEmailsModal
        opened={importModal}
        onClose={() => setImportModal(false)}
      />
      <ContactModal
        opened={contactModal}
        onClose={() => setContactModal(false)}
        editMode={false}
      />
      <ContactModal
        opened={contactModal && contactId !== null}
        onClose={() => {
          setContactModal(false);
          setTimeout(() => {
            setContactId(null);
            setContactData(null);
          }, 1000);
        }}
        editMode={contactId !== null ? true : false}
        data={contactData}
      />
    </DashboardLayout>
  );
};

export default SubscribersPage;

const TableContainer = styled.table`
  width: 100%;
  height: 100%;
  overflow: sroll;
  border-radius: 50px;
  border-spacing: 0;
`;

const TableHead = styled.thead`
  background-color: white;
  width: 100%;
  border-radius: 50px;
`;

const TableBody = styled.tbody`
  background-color: transparent;
  width: 100%;
`;

const TableHeadRow = styled.tr`
  background-color: transparent;
  width: 100%;
  height: 60px;
  text-align: left;
`;

const TableRow = styled.tr`
  background-color: transparent;
  width: 100%;
  height: 45px;
  border-bottom: 1px solid gray;
`;

const TableHeadItem = styled.th`
  font-size: 14px;
  text-align: left;
  padding: 0 35px;
  color: #013a4f;
  font-weight: 700;
  text-align: left;

  text-transform: uppercase;
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: left;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: left;
  }
`;

const TableDescription = styled.td`
  font-size: 15px;
  padding: 30px 35px;
  font-weight: 500;
  border-bottom: 0.05px solid rgba(194, 194, 194, 0.4);
  min-width: 8rem;
  text-align: left;
`;
const NoListingsDescription = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding: 30px 0;
  font-weight: 500;
  border-bottom: 0.05px solid rgba(194, 194, 194, 0.4);
  text-align: center;
`;
