import axios from "axios";

const authUrl = process.env.REACT_APP_SERVER_AUTH_URL;

const login = async (userData) => {
  const response = await axios.post(`${authUrl}/login`, userData);

  if (response.data) {
    sessionStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const forgotPassword = async (userData) => {
  const response = await axios.put(`${authUrl}/forgot-password`, userData);
  return response.data;
};

const resetPassword = async (userData) => {
  const response = await axios.put(`${authUrl}/reset-password`, userData);
  return response.data;
};

const register = async (userData) => {
  const response = await axios.post(`${authUrl}/register`, userData);

  return response.data;
};

const signOut = () => {
  sessionStorage.removeItem("user");
};

const authService = {
  register,
  login,
  signOut,
  forgotPassword,
  resetPassword,
};

export default authService;
