import React, { useState } from "react";
import {
  CareersForm,
  DashboardLayout,
  errorToast,
  successToast,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { createJobListing } from "../../redux/reducers/jobListingSlice";
import { useNavigate } from "react-router-dom";

const NewJobPage = () => {
  const [jobRole, setJobRole] = useState("");
  const [jobType, setJobType] = useState("Full-Time");
  const [jobRequirements, setJobRequirements] = useState("");
  const [department, setDepartment] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.jobListings
  );
  const { user } = useSelector((state) => state.auth);

  const handleCreateJobListing = (e) => {
    e.preventDefault();

    const jobListingData = {
      jobRole,
      jobType,
      jobRequirements,
      department,
      userName: user?.name,
    };
    dispatch(createJobListing(jobListingData));
    if (!isLoading) {
      setTimeout(() => {
        if (isError) {
          errorToast(message);
        }

        if (isSuccess) {
          successToast("created");
          setTimeout(() => {
            navigate("/dashboard/careers");
          }, 3000);
        }
      }, 1500);
    }
  };

  return (
    <DashboardLayout title="New Job Listing">
      <CareersForm
        handleJobListingFormSubmit={handleCreateJobListing}
        jobRole={jobRole}
        setJobRole={setJobRole}
        jobType={jobType}
        setJobType={setJobType}
        jobRequirements={jobRequirements}
        setJobRequirements={setJobRequirements}
        department={department}
        setDepartment={setDepartment}
        loading={isLoading}
      />
    </DashboardLayout>
  );
};

export default NewJobPage;
