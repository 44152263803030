import React from "react";
import styled from "styled-components";

const SkeletonListingTable = () => {
  return (
    <SkeletonTable>
      <thead>
        <tr>
          <SkeletonTableHead>
            <Line></Line>
          </SkeletonTableHead>
          <SkeletonTableHead>
            <Line></Line>
          </SkeletonTableHead>
          <SkeletonTableHead>
            <Line></Line>
          </SkeletonTableHead>
          <SkeletonTableHead>
            <Line></Line>
          </SkeletonTableHead>
        </tr>
      </thead>
      <tbody>
        <tr>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
        </tr>
        <tr>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
        </tr>
        <tr>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
        </tr>
        <tr>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
          <SkeletonTableDescription>
            <Line></Line>
          </SkeletonTableDescription>
        </tr>
      </tbody>
    </SkeletonTable>
  );
};

export default SkeletonListingTable;

const SkeletonTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  border-color: #ccc;
  width: 100%;
`;

const SkeletonTableHead = styled.th`
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  padding: 25px 15px;
  border-style: solid;
  border-width: 0px;
  overflow: hidden;
  word-break: normal;
  border-color: #ccc;
  color: #fff;
  background-color: rgba(229, 229, 229, 0.3);
`;

const SkeletonTableDescription = styled.td`
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 20px 15px;
  border-style: solid;
  border-width: 0px;
  overflow: hidden;
  word-break: normal;
  border-color: #ccc;
  color: #333;
  border-bottom: 1px solid rgba(229, 229, 229, 0.8);
`;

const Line = styled.div`
  float: left;
  width: 100%;
  height: 25px;
  border-radius: 7px;

  background-image: linear-gradient(
    90deg,
    rgba(229, 229, 229, 0.5) 0px,
    rgba(229, 229, 229, 0.2) 40px,
    rgba(229, 229, 229, 0.5) 80px
  );
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
  @keyframes shine-lines {
    0% {
      background-position: 100%;
    }
    100% {
      background-position: -100%;
    }
  }
`;
