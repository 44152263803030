import React from "react";
import styled from "styled-components";
import { FiSettings, FiUser, FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOutUser, reset } from "../../redux/reducers/authSlice";

const ProfileDropdown = ({ opened }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const userAuthLevel = user?.authLevel;

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSignOut = (e) => {
    e.preventDefault();
    dispatch(signOutUser());
    dispatch(reset());
    delay(500);
    navigate("/login");
  };

  return (
    <ProfileDropdownContianer opened={opened}>
      <ProfileDropdownButton onClick={() => navigate("/dashboard/settings")}>
        <FiSettings size={17} style={{ marginRight: "8px" }} /> Account Settings
      </ProfileDropdownButton>
      {userAuthLevel === "Super Admin" && (
        <ProfileDropdownButton
          onClick={() => navigate("/dashboard/settings/users")}
        >
          <FiUser size={17} style={{ marginRight: "8px" }} /> User List
        </ProfileDropdownButton>
      )}

      <ProfileDropdownButton onClick={handleSignOut}>
        <FiLogOut size={17} style={{ marginRight: "8px" }} /> Sign Out
      </ProfileDropdownButton>
    </ProfileDropdownContianer>
  );
};

export default ProfileDropdown;

const ProfileDropdownContianer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: white;
  position: absolute;
  top: ${(props) => (props.opened ? "95%" : "70%")};
  right: 20px;
  z-index: ${(props) => (props.opened ? "10" : "-100")};
  transition: all 150ms ease-in-out;
  border-top: ${(props) =>
    props.opened ? "4px solid #95F400" : "4px solid transparent"};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 10px 0 rgba(0, 0, 0, 0.1);
`;

const ProfileDropdownButton = styled.button`
  display: inline-flex;
  justify-content: start;
  align-items: center;
  background: transparent;
  border: none;
  font-size: 15px;
  font-weight: 700;
  width: 100%;
  padding: 15px 30px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:first-child {
    margin-top: 0s;
  }

  &:last-child {
    border-top: 1px solid #95f400;
    margin-top: 8px;
    padding: 20px 30px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
