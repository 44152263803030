import React, { useEffect, useState } from "react";
import {
  DashboardLayout,
  ListingsTable,
  errorToast,
  successToast,
} from "../../components";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllNewsletters,
  removeNewsletter,
} from "../../redux/reducers/newsletterSlice";
import { deleteNewsletter } from "../../redux/reducers/singleNewsletterSlice";
import { getAllSubscriptions } from "../../redux/reducers/subscriptionSlice";

const NewslettersPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteItem, setDeleteItem] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { newsletters, isLoading } = useSelector((state) => state.newsletters);
  const { isError, message } = useSelector((state) => state.singleNewsletter);

  useEffect(() => {
    dispatch(getAllNewsletters());
    dispatch(getAllSubscriptions());
  }, [dispatch]);

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(removeNewsletter(deleteItem));
    dispatch(deleteNewsletter(deleteItem));
    setTimeout(() => {
      setLoadingDelete(false);
      setDeleteItem(null);
      successToast("deleted");
    }, 400);
  };
  useEffect(() => {
    if (isError) {
      errorToast(message);
    }
  }, [isError, message]);
  const heads = ["campaign date", "title", "status", "actions"];
  return (
    <DashboardLayout
      title="Newsletters"
      buttons={[
        {
          name: "Manage Email List",
          action: () => navigate("/dashboard/newsletters/subscribers"),
          type: "outlined",
        },

        {
          name: "New Campaign",
          action: () => navigate("/dashboard/newsletters/new"),
          type: "contained",
        },
      ]}
    >
      <ListingsTable
        heads={heads}
        tableData={newsletters}
        type="newsletters"
        loading={isLoading}
        deleteItem={deleteItem}
        setDeleteItem={setDeleteItem}
        loadingDelete={loadingDelete}
        primaryButtonAction={handleDelete}
      />
    </DashboardLayout>
  );
};

export default NewslettersPage;
