import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  errorToast,
  PrimaryButton,
  ResetModal,
  FormInput,
  Spinner,
} from "../components";
import { ReactComponent as LoginFormLogo } from "../assets/images/logo-alft.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser, reset } from "../redux/reducers/authSlice";
import { Helmet } from "react-helmet";

const LoginPage = () => {
  const [showResetModal, setShowResetModal] = useState(false);
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = loginFormData;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      errorToast(message);
    }
    if (isSuccess || !user === null) {
      navigate("/dashboard/approvals");
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const loginFormOnChange = (e) => {
    setLoginFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };

    dispatch(loginUser(userData));
  };

  return (
    <>
      <Helmet>
        <title>Login | ALFT</title>
      </Helmet>
      <PageContainer>
        <LoginFormContainer onSubmit={handleLoginSubmit}>
          <LoginFormLogo style={{ width: "99%" }} />
          <LoginFormInputs>
            <FormInput
              text="Email"
              inputType="email"
              value={email}
              name="email"
              handleOnChange={loginFormOnChange}
            />
            <FormInput
              text="Password"
              inputType="password"
              value={password}
              name="password"
              handleOnChange={loginFormOnChange}
            />
          </LoginFormInputs>
          <ForgotPassword type="button" onClick={() => setShowResetModal(true)}>
            Forgot Your Password?
          </ForgotPassword>
          <div style={{ marginTop: "45px" }}>
            <PrimaryButton type="submit">
              {!isLoading && <span>Login</span>}
              {isLoading && (
                <>
                  <span>Logging in</span> <Spinner />
                </>
              )}
            </PrimaryButton>
          </div>
        </LoginFormContainer>
        <ResetModal
          opened={showResetModal}
          onClose={() => setShowResetModal(false)}
        />
      </PageContainer>
    </>
  );
};

export default LoginPage;

const PageContainer = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafff4;
`;

const LoginFormContainer = styled.form`
  padding-top: 55px;
  padding-bottom: 55px;
  padding-left: 70px;
  padding-right: 70px;
  background-color: #ffffff;
  box-shadow: -4px -4px 10px rgba(0, 0, 0, 0.05),
    4px 4px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

const LoginFormInputs = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
`;

const ForgotPassword = styled.button`
  font-weight: 700;
  font-size: 10px;
  color: #013a4f;
  display: inline-flex;
  justify-content: start;
  align-items: start;
  margin-top: -20px;
  margin-left: -55%;
  text-decoration: none;
  background-color: transparent;
  border: none;
  z-index: 50;
  &:hover {
    cursor: pointer;
  }
`;
