import React from "react";
import styled from "styled-components";

const PostApproval = ({ approvalStatus, setApprovalStatus }) => {
  return (
    <PostApprovalContainer>
      <PostApprovalText>Post Approval</PostApprovalText>
      <PostApprovalButtons>
        <TabbedButton
          type="button"
          onClick={() => {
            setApprovalStatus("approved");
          }}
          active={approvalStatus === "approved" ? true : false}
        >
          Approved
        </TabbedButton>
        <TabbedButton
          type="button"
          onClick={() => {
            setApprovalStatus("pending");
          }}
          active={approvalStatus === "pending" ? true : false}
        >
          Pending
        </TabbedButton>

        <TabbedButton
          type="button"
          onClick={() => {
            setApprovalStatus("not approved");
          }}
          active={approvalStatus === "not approved" ? true : false}
        >
          Not Approved
        </TabbedButton>
      </PostApprovalButtons>
    </PostApprovalContainer>
  );
};

export default PostApproval;

const PostApprovalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

const PostApprovalText = styled.span`
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 800;
`;

const PostApprovalButtons = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 9px;
`;

const TabbedButton = styled.button`
  padding: 13px 29px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.active ? "#95f400" : "transparent")};
  border: 1px solid #95f400;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: #013a4f;
  font-weight: 800;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  &:nth-child(n + 2) {
    border-radius: 0;
    border-left: none;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;
