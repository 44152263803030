import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import responseService from "../services/responseService";

const initialState = {
  responses: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get single response
export const getResponse = createAsyncThunk(
  "response/single",
  async (responseId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await responseService.getResponse(responseId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Send Response
export const sendResponse = createAsyncThunk(
  "response/respond/single",
  async (responseData, thunkAPI) => {
    const data = {
      responderName: responseData.responderName,
      responderMessage: responseData.responderMessage,
      status: responseData.status,
    };
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await responseService.sendResponse(
        data,
        responseData.responseId,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update status of response
export const updateStatus = createAsyncThunk(
  "response/respond/single",
  async (responseData, thunkAPI) => {
    const data = {
      status: responseData.status,
    };
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await responseService.updateStatus(
        data,
        responseData.responseId,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all responses
export const getAllResponses = createAsyncThunk(
  "response",
  async (type, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      const responses = await responseService.getAllResponses(token);
      const sortedResponses = responses.sort((a, b) =>
        b.createdAt.localeCompare(a.createdAt)
      );
      if (type === "Opened") {
        const filteredResponses = sortedResponses.filter(
          (r) => r.status === "opened"
        );
        return filteredResponses;
      }
      if (type === "Responded") {
        const filteredResponses = sortedResponses.filter(
          (r) => r.status === "responded"
        );
        return filteredResponses;
      }
      if (type === "Unopened") {
        const filteredResponses = sortedResponses.filter(
          (r) => r.status === "unopened"
        );
        return filteredResponses;
      } else {
        return sortedResponses;
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const responseSlice = createSlice({
  name: "response",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResponse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.responses = action.payload;
      })
      .addCase(getResponse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllResponses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllResponses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.responses = action.payload;
      })
      .addCase(getAllResponses.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(sendResponse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendResponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(sendResponse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = responseSlice.actions;
export default responseSlice.reducer;
