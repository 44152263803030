import axios from "axios";

const adminUrl = process.env.REACT_APP_SERVER_ADMIN_URL;

const updateAdmin = async (userData, userId, jwt) => {
  const response = await axios.put(`${adminUrl}/${userId}`, userData, {
    headers: {
      token: `Bearer ${jwt}`,
    },
  });

  return response.data;
};

const getAllAdmins = async (token) => {
  const response = await axios.get(adminUrl, {
    headers: {
      token: `Bearer ${token}`,
    },
  });

  return response.data;
};

const deleteAdmin = async (adminId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${adminUrl}/${adminId}`, config);

  return response.data;
};

const authService = {
  updateAdmin,
  getAllAdmins,
  deleteAdmin,
};

export default authService;
