import React from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./sharedStyles.css";
const SizeStyle = Quill.import("attributors/style/size");

Quill.register(SizeStyle, true);

const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);
const modules = {
  toolbar: {
    container: "#toolbar",
  },
};
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "list",
  "bullet",
  "link",
];

const CustomToolbar = ({ small }) => {
  return (
    <Toolbar small={small} id="toolbar">
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="bullet" />
        <button className="ql-list" value="ordered" />
      </span>
      <span className="ql-formats">
        <select className="ql-align" />
      </span>

      <span className="ql-formats">
        <select className="ql-font" defaultValue="arial">
          <option value="arial">Arial</option>
          <option value="comic-sans">Comic Sans</option>
          <option value="courier-new">Courier New</option>
          <option value="georgia">Georgia</option>
          <option value="helvetica">Helvetica</option>
          <option value="lucida">Lucida</option>
        </select>
      </span>
      <span className="ql-formats">
        <select className="ql-header" defaultValue="3">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="3">Normal</option>
        </select>
      </span>
      <span className="ql-formats">
        <select className="ql-size" defaultValue="15px">
          <option value="10px">Small</option>
          <option value="15px">Medium</option>
          <option value="18px">Large</option>
          <option value="32px">Huge</option>
        </select>
      </span>

      <span className="ql-formats">
        <button className="ql-link" />
      </span>
    </Toolbar>
  );
};

const TextEditor = ({
  text,
  textData,
  setTextData,
  smallerEditor = false,
  defaultValue,
  disabled,
}) => {
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <input
        style={{
          position: "absolute",
          left: "5%",
          top: "13%",
          zIndex: "100",
          border: "none",
          background: "transparent",
          color: "transparent",
          outline: "none",
        }}
        value={textData}
        required
      />
      <TextEditorContainer small={smallerEditor}>
        <CustomToolbar small={smallerEditor} />
        <ReactQuill
          theme="snow"
          onChange={setTextData}
          value={textData}
          modules={modules}
          formats={formats}
          style={{ width: smallerEditor ? "91%" : "100%", height: "17.4rem" }}
        />
      </TextEditorContainer>
    </div>
  );
};

export default TextEditor;

const TextEditorContainer = styled.div`
  width: ${(props) => (props.small ? "91%" : "100%")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Toolbar = styled.div`
  width: ${(props) => (props.small ? "91%" : "100%")};
  background-color: #013a4f;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border: none;
`;
