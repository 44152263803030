import React, { useState } from "react";
import styled from "styled-components";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { HelperText, InputLabel } from "./Texts";

const FormInput = ({
  text,
  inputType,
  uppercase = false,
  plain,
  handleOnChange,
  value,
  short,
  backgroundWhite,
  dropdownValue,
  dropdownOptions,
  name,
  helperText,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  return (
    <FormInputContainer>
      {text && (
        <InputLabel
          style={{ textTransform: uppercase ? "uppercase" : "capitalize" }}
        >
          {text}
        </InputLabel>
      )}
      {helperText && <HelperText>{helperText}</HelperText>}
      {inputType !== "dropdown" && (
        <FormInputBox
          value={value}
          plain={plain}
          name={name}
          type={
            inputType === "password"
              ? passwordShown
                ? "text"
                : "password"
              : inputType
          }
          short={short}
          onChange={handleOnChange}
          backgroundWhite={backgroundWhite}
          required
        />
      )}
      {inputType === "password" && (
        <PasswordShowHideButton
          stacked={text ? true : false}
          onClick={(e) => {
            e.preventDefault();
            setPasswordShown(!passwordShown);
          }}
        >
          {!passwordShown && <IoMdEye size={20} />}
          {passwordShown && <IoMdEyeOff size={20} />}
        </PasswordShowHideButton>
      )}
      {inputType === "dropdown" && (
        <FormDropdownInput
          value={dropdownValue}
          onChange={handleOnChange}
          required
          short={short}
          plain={plain}
          name={name}
        >
          {dropdownOptions.map((option, index) => (
            <option
              key={index}
              className="w-full h-10 px-3 text-lg border-2 border-gray-400 rounded-lg "
              value={option}
            >
              {option}
            </option>
          ))}
        </FormDropdownInput>
      )}
    </FormInputContainer>
  );
};

export default FormInput;

const FormInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-bottom: 30px;
  position: relative;
`;

const FormInputBox = styled.input`
  width: ${(props) => (props.short ? "85%" : "100%")};
  box-sizing: border-box;
  border: ${(props) =>
    props.plain
      ? "0.1px solid rgba(194, 194, 194, 0.4)"
      : "0.05px solid rgba(149, 244, 0, 0.3)"};
  background-color: ${(props) =>
    props.plain
      ? "#fffff"
      : "#fafff4" || props.backgroundWhite
      ? "#fffff"
      : ""};
  color: ${(props) => (props.readOnly ? "gray" : "")};
  padding: 10px;
  border-radius: 7px;
  &:focus {
    outline: none;
  }
`;

const PasswordShowHideButton = styled.span`
  position: absolute;
  top: ${(props) => (props.stacked ? "39%" : "10px")};
  right: 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #013a4f;
`;

const FormDropdownInput = styled.select`
  width: ${(props) => (props.short ? "85%" : "100%")};
  box-sizing: border-box;
  border: ${(props) =>
    props.plain
      ? "0.1px solid rgba(194, 194, 194, 0.4)"
      : "0.05px solid rgba(149, 244, 0, 0.3)"};
  background-color: ${(props) =>
    props.plain
      ? "#fffff"
      : "#fafff4" || props.backgroundWhite
      ? "#fffff"
      : ""};
  color: ${(props) => (props.readOnly ? "gray" : "")};

  padding: 10px;
  border-radius: 7px;
  &:focus {
    outline: none;
  }
  & * {
    background-color: white;
    padding: 5px;
    border: none;
  }
`;
