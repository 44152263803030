import React, { useEffect, useState } from "react";
import {
  errorToast,
  NewsAndEventsForm,
  DashboardLayout,
  successToast,
} from "../../components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPost } from "../../redux/reducers/newsAndEventSlice";
import { updatePost } from "../../redux/reducers/singleNewsAndEventSlice";

import { useNavigate, useLocation } from "react-router-dom";

const EditEventPage = ({ userAuthLevel }) => {
  const [title, setTitle] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [newsItem, setNewsItem] = useState("");
  const [coverImage, setCoverImage] = useState("string");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [category, setCategory] = useState("News");
  const [userName, setUserName] = useState("");

  const id = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;

  const { newsAndEvents } = useSelector((state) => state.newsAndEvents);
  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.singleNewsAndEvent
  );
  const { user } = useSelector((state) => state.auth);

  const newsAndEventId = id.id;

  useEffect(() => {
    dispatch(getPost(newsAndEventId));
  }, [dispatch, newsAndEventId]);

  useEffect(() => {
    setTitle(newsAndEvents.title);
    setAuthorName(newsAndEvents.authorName);
    setCoverImage(newsAndEvents.coverImage);
    setNewsItem(newsAndEvents.newsItem);
    setApprovalStatus(newsAndEvents.status);
    setCategory(newsAndEvents.category);
    setUserName(newsAndEvents.userName);
  }, [newsAndEvents]);

  const handleSaveNewsAndEvent = (e) => {
    e.preventDefault();
    if (userAuthLevel === "Super Admin" && currentRoute.includes("approvals")) {
      setTimeout(() => {
        const newsAndEventData = {
          title,
          authorName,
          newsItem,
          coverImage,
          approvalStatus,
          newsAndEventId,
          category,
        };
        dispatch(updatePost(newsAndEventData));
        if (isError) {
          errorToast(message);
        }

        if (isSuccess) {
          successToast("saved");
          setTimeout(() => {
            if (
              userAuthLevel === "Super Admin" &&
              currentRoute.includes("approvals")
            ) {
              navigate("/dashboard/approvals");
            } else {
              navigate("/dashboard/news-and-events");
            }
          }, 3000);
        }
      }, 500);
    } else {
      const newsletterData = {
        title,
        authorName,
        newsItem,
        coverImage,
        newsAndEventId,
        approvalStatus,
        category,
        userName: user?.name,
      };
      dispatch(updatePost(newsletterData));
      if (isError) {
        errorToast(message);
      }

      if (isSuccess) {
        successToast("saved");
        setTimeout(() => {
          if (
            userAuthLevel === "Super Admin" &&
            currentRoute.includes("approvals")
          ) {
            navigate("/dashboard/approvals");
          } else {
            navigate("/dashboard/news-and-events");
          }
        }, 3000);
      }
    }
  };

  return (
    <DashboardLayout title="Edit Event" subText={`Created By: ${userName}`}>
      <NewsAndEventsForm
        newsAndEventId={id}
        handleNewsAndEventFormSubmit={handleSaveNewsAndEvent}
        title={title}
        setTitle={setTitle}
        authorName={authorName}
        setAuthorName={setAuthorName}
        newsItem={newsItem}
        setNewsItem={setNewsItem}
        coverImage={coverImage}
        setCoverImage={setCoverImage}
        approvalStatus={approvalStatus}
        setApprovalStatus={setApprovalStatus}
        loading={isLoading}
        userAuthLevel={userAuthLevel}
        category={category}
        setCategory={setCategory}
        admin={
          userAuthLevel === "Super Admin" && currentRoute.includes("approvals")
            ? true
            : false
        }
        userName={user?.name}
      />
    </DashboardLayout>
  );
};

export default EditEventPage;
