import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "../shared/Modal";
import { PrimaryButton } from "../shared/Buttons";
import FormInput from "../shared/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addNewContact,
  updateContact,
} from "../../redux/reducers/subscriptionSlice";
import { errorToast, successToast } from "../shared/Toasts";
import { Spinner } from "../shared/Spinner";

const ContactModal = ({ onClose, opened, editMode, data }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactId, setContactId] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.subscriptions
  );
  useEffect(() => {
    if (editMode) {
      if (data !== undefined) {
        setName(data.name);
        setEmail(data.email);
        setContactId(data._id);
      }
    }
  }, [editMode, data]);

  const handleAddNewContact = (e) => {
    e.preventDefault();
    setLoading(true);
    const contactData = {
      name,
      email,
    };
    dispatch(addNewContact(contactData));
    setTimeout(() => {
      if (!isLoading) {
        onClose();
      }
    }, 2000);

    setTimeout(() => {
      if (isError) {
        setLoading(false);
        errorToast(message);
      }

      if (isSuccess) {
        setLoading(false);
        successToast("", "Successfully Added New Email Contact");
      }
    }, 2000);
  };

  const handleUpdateContact = (e) => {
    e.preventDefault();
    setLoading(true);
    const contactData = {
      name,
      email,
      contactId,
    };
    dispatch(updateContact(contactData));
    setTimeout(() => {
      if (!isLoading) {
        onClose();
      }
    }, 3000);

    setTimeout(() => {
      if (isError) {
        setLoading(false);
        errorToast(message);
      }

      if (isSuccess) {
        setLoading(false);
        successToast("saved");
      }
    }, 3000);
  };

  return (
    <Modal onClose={onClose} backgroundWhite={true} opened={opened}>
      <ContactModalContent
        onSubmit={editMode ? handleUpdateContact : handleAddNewContact}
      >
        <Title>{editMode ? "Edit Contact" : "Add New Contact"}</Title>
        <div style={{ width: "100%", marginTop: "15px" }}>
          <FormInput
            text="Full Name"
            inputType="text"
            value={name}
            name="name"
            handleOnChange={(e) => setName(e.target.value)}
          />
          <ContactModalInputContainer>
            <FormInput
              text="Email"
              inputType="text"
              value={email}
              name="email"
              handleOnChange={(e) => setEmail(e.target.value)}
            />
          </ContactModalInputContainer>
        </div>
        <PrimaryButton style={{ marginTop: "20px" }}>
          {loading ? (
            <>
              <span>{editMode ? "Saving Changes" : "Creating Contact"}</span>
              <Spinner />
            </>
          ) : (
            <span>{editMode ? "Save Changes" : "Create Contact"}</span>
          )}
        </PrimaryButton>
      </ContactModalContent>
    </Modal>
  );
};

export default ContactModal;

const ContactModalContent = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 30px;
  width: 100%;
  min-width: 18rem;
`;

const Title = styled.span`
  font-size: 22px;
  font-weight: 800;
  margin-top: 25px;
  padding-bottom: 15px;
  color: #013a4f;
  text-align: center;
`;

const ContactModalInputContainer = styled.div`
  width: 100%;
  margin-top: -10px;
`;
