import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  DashboardLayout,
  errorToast,
  FormButtonsContainer,
  PrimaryButton,
  SecondaryButton,
  Spinner,
  successToast,
} from "../../components";
import TextEditor from "../../components/shared/TextEditor";
import { useSelector, useDispatch } from "react-redux";
import {
  getResponse,
  sendResponse,
  updateStatus,
} from "../../redux/reducers/responseSlice";
import { useNavigate, useParams } from "react-router-dom";
import format from "date-fns/format";
import { HelperText, InputLabel } from "../../components/shared/Texts";

const ViewResponsePage = () => {
  const [responderMessage, setResponderMessage] = useState(
    <SkeletonTextLoader />
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const responseId = useParams().id;
  const { responses, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.responses
  );
  const { user } = useSelector((state) => state.auth);
  const responderName = user.name;
  useEffect(() => {
    dispatch(getResponse(responseId));
  }, [dispatch, responseId]);
  useEffect(() => {
    const data = {
      responseId,
      status: "opened",
    };
    dispatch(updateStatus(data));
  }, [dispatch, responseId]);

  const handleSendResponse = (e) => {
    e.preventDefault();
    const responseData = {
      responseId,
      responderMessage,
      responderName,
      status: "responded",
    };
    dispatch(sendResponse(responseData));
    if (isError) {
      errorToast(message);
    }

    if (isSuccess) {
      successToast(<SkeletonTextLoader />, "Message Sent");
      setTimeout(() => {
        navigate("/dashboard/responses");
      }, 3000);
    }
  };
  return (
    <DashboardLayout title={`Response Details`}>
      <ResponseContainer>
        <ResponseContent>
          <div>
            <ResponseMeta>Date</ResponseMeta>
            <ResponseMetaData>
              {responses.createdAt !== undefined ? (
                format(new Date(responses.createdAt), "d-MM-yyyy ")
              ) : (
                <SkeletonTextLoader />
              )}
            </ResponseMetaData>
          </div>
          <div>
            <ResponseMeta>Name</ResponseMeta>
            <ResponseMetaData>
              {isLoading ? <SkeletonTextLoader /> : responses.name}
            </ResponseMetaData>
          </div>
          <div>
            <ResponseMeta>Email</ResponseMeta>
            <ResponseMetaData>
              {isLoading ? <SkeletonTextLoader /> : responses.email}
            </ResponseMetaData>
          </div>
          <div>
            <ResponseMeta>Phone Number</ResponseMeta>
            <ResponseMetaData>
              {isLoading ? <SkeletonTextLoader /> : responses.phoneNumber}
            </ResponseMetaData>
          </div>
          <div>
            <ResponseMeta>Business Name</ResponseMeta>
            <ResponseMetaData>
              {isLoading ? <SkeletonTextLoader /> : responses.businessName}
            </ResponseMetaData>
          </div>
          <div>
            <ResponseMeta>Country</ResponseMeta>
            <ResponseMetaData>
              {isLoading ? <SkeletonTextLoader /> : responses.country}
            </ResponseMetaData>
          </div>
        </ResponseContent>
        <div>
          <ResponseMeta>Message</ResponseMeta>
          <ResponseMetaData>
            {isLoading ? <SkeletonTextLoader /> : responses.message}
          </ResponseMetaData>
        </div>

        <form style={{ width: "100%" }} onSubmit={handleSendResponse}>
          <SendResponseInput>
            <InputLabel>Send Response</InputLabel>
            <HelperText>
              Type the response you want to send to the message
            </HelperText>
            <TextEditor
              textData={responderMessage}
              setTextData={setResponderMessage}
              text="sendMessage"
              smallerEditor={false}
            />
          </SendResponseInput>
          <FormButtonsContainer right>
            <SecondaryButton
              onClick={() => navigate("/dashboard/responses")}
              marginRight
            >
              Discard
            </SecondaryButton>
            <PrimaryButton type="submit">
              {isLoading ? (
                <>
                  <span>Sending</span>
                  <Spinner />
                </>
              ) : (
                <span>Send</span>
              )}
            </PrimaryButton>{" "}
          </FormButtonsContainer>
        </form>
      </ResponseContainer>
    </DashboardLayout>
  );
};

export default ViewResponsePage;

const ResponseContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 0 35px;
`;

const ResponseContent = styled.div`
  width: 95%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
`;

const ResponseMeta = styled.span`
  color: black;
  font-weight: 500;
  font-size: 19px;
  padding-bottom: 13px;
`;

const ResponseMetaData = styled.p`
  color: gray;
  font-size: 16px;
  padding-bottom: 13px;
  min-height: 25px;
`;

const SendResponseInput = styled.div`
  width: 100%;
  margin-top: 23px;
  padding-top: 30px;
  border-top: 0.05px solid rgba(194, 194, 194, 0.5);
  display: flex;
  flex-direction: column;
`;

const SkeletonTextLoader = styled.div`
  float: left;
  width: 100%;
  height: 25px;
  border-radius: 7px;

  background-image: linear-gradient(
    90deg,
    rgba(229, 229, 229, 0.5) 0px,
    rgba(229, 229, 229, 0.2) 40px,
    rgba(229, 229, 229, 0.5) 80px
  );
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
  @keyframes shine-lines {
    0% {
      background-position: 100%;
    }
    100% {
      background-position: -100%;
    }
  }
`;
