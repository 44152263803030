import React, { useEffect, useState } from "react";
import {
  DashboardLayout,
  CareersForm,
  errorToast,
  successToast,
} from "../../components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobListing,
  updateApprovalStatus,
} from "../../redux/reducers/jobListingSlice";
import { updateJobListing } from "../../redux/reducers/singleJobListingSlice";

import { useNavigate, useLocation } from "react-router-dom";

const EditJobPage = ({ userAuthLevel }) => {
  const [jobRole, setJobRole] = useState("");
  const [jobType, setJobType] = useState("");
  const [jobRequirements, setJobRequirements] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [department, setDepartment] = useState("");
  const [userName, setUserName] = useState("");

  const currentRoute = useLocation().pathname;
  const id = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobListings } = useSelector((state) => state.jobListings);
  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.singleJobListing
  );
  const { user } = useSelector((state) => state.auth);

  const jobListingId = id.id;
  useEffect(() => {
    dispatch(getJobListing(jobListingId));
  }, [dispatch, jobListingId]);

  useEffect(() => {
    setJobRole(jobListings.jobRole);
    setJobType(jobListings.jobType);
    setJobRequirements(jobListings.jobRequirements);
    setApprovalStatus(jobListings.status);
    setDepartment(jobListings.department);
    setUserName(jobListings.userName);
  }, [jobListings]);

  const handleSaveJobListingForm = (e) => {
    e.preventDefault();
    if (userAuthLevel === "Super Admin" && currentRoute.includes("approvals")) {
      setTimeout(() => {
        const jobListingData = {
          jobRole,
          jobType,
          jobRequirements,
          approvalStatus,
          jobListingId,
          department,
          isActive: true,
        };
        dispatch(updateJobListing(jobListingData));
        if (isError) {
          errorToast(message);
        }

        if (isSuccess) {
          successToast("saved");
          setTimeout(() => {
            if (
              userAuthLevel === "Super Admin" &&
              currentRoute.includes("approvals")
            ) {
              navigate("/dashboard/approvals");
            } else {
              navigate("/dashboard/careers");
            }
          }, 3000);
        }
      }, 500);
    } else {
      const jobListingData = {
        jobRole,
        jobType,
        jobRequirements,
        approvalStatus,
        department,
        jobListingId,
        userName: user?.name,
      };
      dispatch(updateJobListing(jobListingData));
      if (isError) {
        errorToast(message);
      }

      if (isSuccess) {
        successToast("saved");
        setTimeout(() => {
          if (
            userAuthLevel === "Super Admin" &&
            currentRoute.includes("approvals")
          ) {
            navigate("/dashboard/approvals");
          } else {
            navigate("/dashboard/careers");
          }
        }, 3000);
      }
    }
  };

  return (
    <DashboardLayout
      title="Edit Job Listing"
      subText={`Created By: ${userName}`}
    >
      <CareersForm
        jobListingId={id}
        handleJobListingFormSubmit={handleSaveJobListingForm}
        jobRole={jobRole}
        setJobRole={setJobRole}
        jobType={jobType}
        setJobType={setJobType}
        jobRequirements={jobRequirements}
        setJobRequirements={setJobRequirements}
        approvalStatus={approvalStatus}
        setApprovalStatus={setApprovalStatus}
        loading={isLoading}
        userAuthLevel={userAuthLevel}
        department={department}
        setDepartment={setDepartment}
        status={jobListings.status}
        admin={
          userAuthLevel === "Super Admin" && currentRoute.includes("approvals")
            ? true
            : false
        }
      />
    </DashboardLayout>
  );
};

export default EditJobPage;
