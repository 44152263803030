import React, { useEffect, useState } from "react";
import {
  DashboardLayout,
  errorToast,
  Popout,
  successToast,
  ListingsTable,
} from "../../components";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllJobListings,
  removeJobListing,
  updateStatus,
} from "../../redux/reducers/jobListingSlice";
import {
  deleteJoblisting,
  updateJobListing,
} from "../../redux/reducers/singleJobListingSlice";

const CareersPage = () => {
  const [deleteItem, setDeleteItem] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [activate, setActivate] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [loadingEnable, setLoadingEnable] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobListings, isLoading } = useSelector((state) => state.jobListings);
  const { isError, message } = useSelector((state) => state.singleJobListing);

  useEffect(() => {
    dispatch(getAllJobListings());
  }, [dispatch]);

  const handleUpdateStatus = () => {
    setLoadingEnable(true);
    if (activate?.includes(activeItem)) {
      const data = {
        jobListingId: activeItem,
        activeStatus: false,
      };
      dispatch(updateJobListing(data));
      dispatch(updateStatus(data));
      setTimeout(() => {
        setActivate(
          activate?.map((item) => {
            return item !== activeItem;
          })
        );
        setLoadingEnable(false);
        setActiveItem(null);
        successToast("", "Successfully Updated Status Of Listing");
      }, 700);
    } else {
      const data = {
        jobListingId: activeItem,
        activeStatus: true,
      };
      dispatch(updateJobListing(data));
      dispatch(updateStatus(data));
      setTimeout(() => {
        activate.push(activeItem);
        setLoadingEnable(false);
        setActiveItem(null);
        successToast("", "Successfully Updated Status Of Listing");
      }, 700);
    }
  };

  useEffect(() => {
    console.log(jobListings);
    const activeJobListings =
      jobListings.length > 1
        ? jobListings.filter((j) => j.isActive === true)
        : [];
    activeJobListings.length > 0 &&
      activeJobListings.map((j) => {
        return activate.push(j._id);
      });
  }, [activate, jobListings]);

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(removeJobListing(deleteItem));
    dispatch(deleteJoblisting(deleteItem));
    setTimeout(() => {
      setLoadingDelete(false);
      setDeleteItem(null);
      successToast("deleted");
    }, 400);
  };
  useEffect(() => {
    if (isError) {
      errorToast(message);
    }
  }, [isError, message]);

  const heads = ["position", "job type", "status", "actions"];
  return (
    <DashboardLayout
      title="Ongoing Vacancies"
      buttons={[
        {
          name: "New Vacancy",
          action: () => navigate("/dashboard/careers/new"),
          type: "contained",
        },
      ]}
    >
      <ListingsTable
        heads={heads}
        tableData={jobListings}
        type="careers"
        loading={isLoading}
        deleteItem={deleteItem}
        setDeleteItem={setDeleteItem}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        primaryButtonAction={handleDelete}
        loadingDelete={loadingDelete}
        activate={activate}
        setActivate={setActivate}
        handleUpdateStatus={handleUpdateStatus}
        loadingEnable={loadingEnable}
      />
      {/* Enable Popup */}
      <Popout
        onClose={() => setActiveItem(null)}
        boldText="DO YOU WANT TO ENABLE
        THIS JOB LISTING?"
        primaryButtonText="Enable"
        primaryLoadingButtonText="Enabling"
        secondaryButtonText="Cancel"
        opened={
          activeItem !== null
            ? !activate.includes(activeItem)
              ? true
              : false
            : false
        }
        primaryButtonAction={handleUpdateStatus}
        loadingAction={loadingEnable}
      />
      {/* Disable Popup */}
      <Popout
        onClose={() => setActiveItem(null)}
        boldText="ARE YOU SURE YOU WANT 
        TO DISABLE THIS JOB LISTING?"
        text="Dont worry! You can enable it again."
        primaryButtonText="Disable"
        primaryLoadingButtonText="Disabling"
        secondaryButtonText="Cancel"
        opened={
          activeItem !== null
            ? activate.includes(activeItem)
              ? true
              : false
            : false
        }
        primaryButtonAction={handleUpdateStatus}
        loadingAction={loadingEnable}
      />
    </DashboardLayout>
  );
};

export default CareersPage;
