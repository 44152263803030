import React, { useRef, useState } from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import { PrimaryButton, SecondaryButton } from "./Buttons";
import Header from "./Header";
import { Helmet } from "react-helmet";
import { HelperText } from "./Texts";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import useOutsideClick from "../../utils/useDetectOutsideClick";

const DashboardLayout = ({ children, title, subText, buttons, dropdown }) => {
  const [selectedFilter, setSelectedFilter] = useState(dropdown?.defaultValue);
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    setShowDropdown(false);
  });

  return (
    <>
      <Helmet>
        <title>{title} | ALFT</title>
      </Helmet>

      <Container>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <DashboardContentContainer>
          <Header />
          <DashboardTitleContainer>
            <div>
              <DashboardTitle>{title}</DashboardTitle>
              {subText !== undefined && <HelperText>{subText}</HelperText>}
            </div>
            {dropdown && (
              <FilterContainer ref={ref}>
                <SelectedFilterButton
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  {selectedFilter}
                  {!showDropdown && (
                    <AiFillCaretDown
                      style={{
                        marginLeft: "5px",
                      }}
                    />
                  )}
                  {showDropdown && (
                    <AiFillCaretUp
                      style={{
                        marginLeft: "5px",
                      }}
                    />
                  )}
                </SelectedFilterButton>
                {showDropdown && (
                  <FilterDropdownContainer>
                    {dropdown.items.map((f) => (
                      <FilterButton
                        onClick={() => {
                          setSelectedFilter(f);
                          setShowDropdown(false);
                          dropdown.action(f);
                        }}
                      >
                        {f}
                      </FilterButton>
                    ))}
                  </FilterDropdownContainer>
                )}
              </FilterContainer>
            )}
            {buttons?.length > 0 && (
              <div>
                {buttons.map((button) => (
                  <div
                    style={{
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {button.type === "outlined" && (
                      <SecondaryButton
                        style={{ marginRight: "15px" }}
                        onClick={button.action}
                      >
                        {button.name}
                      </SecondaryButton>
                    )}

                    {button.type === "contained" && (
                      <PrimaryButton onClick={button.action}>
                        {button.name}
                      </PrimaryButton>
                    )}
                  </div>
                ))}
              </div>
            )}
          </DashboardTitleContainer>
          <DashboardContent>{children}</DashboardContent>
        </DashboardContentContainer>
      </Container>
    </>
  );
};

export default DashboardLayout;

const Container = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const SidebarContainer = styled.div`
  padding: 0;
  margin: 0;
  width: 21.56%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: fixed;
  left: 0;
`;
const DashboardContentContainer = styled.div`
  margin: 0;
  width: 78.44%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  background-color: #fafff4;
  position: relative;
  overflow-y: hidden;
  margin-left: 21.56%;
  min-height: 100vh;
`;
const DashboardContent = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  overflow-y: hidden;
`;

const DashboardTitleContainer = styled.div`
  padding-top: 35px;
  padding-left: 35px;
  padding-bottom: 35px;
  margin: 0;
  width: 90%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

const DashboardTitle = styled.h1`
  font-size: 35px;
  font-weight: 800;
  color: #013a4f;
  text-transform: uppercase;
`;

const FilterContainer = styled.div`
  position: relative;
  min-width: 250px;
  max-width: 250px;
`;

const FilterDropdownContainer = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 5px;
  padding: 0px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 10px 0 rgba(0, 0, 0, 0.1);
`;
const SelectedFilterButton = styled.button`
  width: 100%;
  padding: 17px 15px;
  background-color: white;
  border: 2px solid #95f400;
  border-radius: 10px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #0083b2;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
  }
`;
const FilterButton = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  position: relative;
  padding: 18px 15px;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #95f400;
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
  }
  &:last-child {
    border-bottom: none;
  }
`;
