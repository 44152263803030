import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastConfig = {
  position: "top-right",
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export function successToast(type, message) {
  toast.success(
    type === "saved"
      ? "Successfully Saved Changes"
      : type === "created"
      ? "Successfully Created Post"
      : type === "deleted"
      ? "Successfully Deleted Post"
      : message,
    toastConfig
  );
}

export function errorToast(message) {
  toast.error(message, toastConfig);
}
