import axios from "axios";

const responseUrl = process.env.REACT_APP_SERVER_RESPONSES_URL;

// Get single newsletter
const getResponse = async (responseId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${responseUrl}/${responseId}`, config);

  return response.data;
};

const getAllResponses = async (token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.get(responseUrl, config);

  return response.data;
};
const sendResponse = async (responseData, responseId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${responseUrl}/respond/${responseId}`,
    responseData,
    config
  );

  return response.data;
};

const updateStatus = async (responseData, responseId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${responseUrl}/${responseId}`,
    responseData,
    config
  );

  return response.data;
};

const responseService = {
  getResponse,
  getAllResponses,
  sendResponse,
  updateStatus,
};

export default responseService;
