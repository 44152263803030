import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  errorToast,
  FormInput,
  successToast,
  PrimaryButton,
  Spinner,
} from "../components";
import { ReactComponent as PasswordResetFormLogo } from "../assets/images/logo-alft.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../redux/reducers/authSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const PasswordResetPage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { isLoading, message, isError, isSuccess } = useSelector(
    (state) => state.auth
  );
  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      const userData = {
        password,
        token,
      };
      dispatch(resetPassword(userData));
      setTimeout(() => {
        if (!isLoading) {
          if (isSuccess) {
            successToast(
              "",
              "Your Passsword Has Been Successfully Changed! Please Login With Your New Credential."
            );
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          }
        }
      }, 500);
    } else {
      setPassword("");
      setConfirmPassword("");
      errorToast("Passwords Don't Match!");
    }
  };

  useEffect(() => {
    if (isError) {
      errorToast(message);
    }
  }, [isError, message, navigate, isSuccess]);

  return (
    <>
      <Helmet>
        <title>Reset Password | ALFT</title>
      </Helmet>

      <PageContainer>
        <PasswordResetFormContainer onSubmit={handleForgotPasswordSubmit}>
          <PasswordResetFormLogo style={{ width: "99%" }} />
          <PasswordResetHeading>Reset Your Password</PasswordResetHeading>
          <PasswordResetFormInputs>
            <FormInput
              text="Type new password"
              inputType="password"
              uppercase={false}
              handleOnChange={(e) => setPassword(e.target.value)}
            />
            <FormInput
              text="Confirm your password"
              inputType="password"
              uppercase={false}
              handleOnChange={(e) => setConfirmPassword(e.target.value)}
            />
          </PasswordResetFormInputs>
          <PrimaryButton type="submit">
            {!isLoading && <span>Reset Password</span>}
            {isLoading && (
              <>
                <span>Resetting Password</span> <Spinner />
              </>
            )}
          </PrimaryButton>
        </PasswordResetFormContainer>
      </PageContainer>
    </>
  );
};

export default PasswordResetPage;

const PageContainer = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafff4;
`;

const PasswordResetFormContainer = styled.form`
  padding-top: 55px;
  padding-bottom: 55px;
  padding-left: 70px;
  padding-right: 70px;
  background-color: #ffffff;
  box-shadow: -4px -4px 10px rgba(0, 0, 0, 0.05),
    4px 4px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

const PasswordResetFormInputs = styled.form`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 45px;
`;

const PasswordResetHeading = styled.h1`
  font-weight: 900;
  font-size: 25px;
  color: #013a4f;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-top: 30px;
`;
