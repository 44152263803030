import React, { useEffect, useState } from "react";
import {
  errorToast,
  DashboardLayout,
  NewslettersForm,
  successToast,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { updatePost } from "../../redux/reducers/singleNewsletterSlice";
import { getPost } from "../../redux/reducers/newsletterSlice";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { getAllSubscriptions } from "../../redux/reducers/subscriptionSlice";

const EditNewslettersPage = ({ userAuthLevel }) => {
  const [name, setName] = useState("");
  const [campaignItem, setCampaignItem] = useState("");
  const [title, setTitle] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [userName, setUserName] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;

  const id = useParams();
  const newsletterId = id.id;

  const { newsletters } = useSelector((state) => state.newsletters);
  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.singleNewsletter
  );
  const { user } = useSelector((state) => state.auth);

  const { subscriptions } = useSelector((state) => state.subscriptions);
  useEffect(() => {
    dispatch(getAllSubscriptions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPost(newsletterId));
  }, [dispatch, newsletterId]);

  useEffect(() => {
    setName(newsletters.name);
    setCampaignItem(newsletters.campaignItem);
    setUserName(newsletters.userName);
    setTitle(newsletters.title);
    setApprovalStatus(newsletters.status);
  }, [newsletters]);

  const handleSaveNewsAndEvent = (e) => {
    e.preventDefault();
    if (userAuthLevel === "Super Admin" && currentRoute.includes("approvals")) {
      setTimeout(() => {
        const newsletterData = {
          name,
          campaignItem,
          newsletterId,
          approvalStatus,
        };
        dispatch(updatePost(newsletterData));
        if (isError) {
          errorToast(message);
        }

        if (isSuccess) {
          successToast("saved");
          setTimeout(() => {
            if (
              userAuthLevel === "Super Admin" &&
              currentRoute.includes("approvals")
            ) {
              navigate("/dashboard/approvals");
            } else {
              navigate("/dashboard/newsletters");
            }
          }, 3000);
        }
      }, 500);
    } else {
      const newsletterData = {
        name,
        campaignItem,
        newsletterId,
        approvalStatus,
        userName: user?.name,
      };
      dispatch(updatePost(newsletterData));
      if (isError) {
        errorToast(message);
      }

      if (isSuccess) {
        successToast("saved");
        setTimeout(() => {
          if (
            userAuthLevel === "Super Admin" &&
            currentRoute.includes("approvals")
          ) {
            navigate("/dashboard/approvals");
          } else {
            navigate("/dashboard/newsletters");
          }
        }, 3000);
      }
    }
  };

  return (
    <DashboardLayout
      title="Edit Newsletter"
      subText={`Created By: ${userName}`}
    >
      <NewslettersForm
        primaryBtnName="Save Changes"
        secondaryBtnName="Cancel"
        newsletterId={newsletterId}
        campaignItem={campaignItem}
        setCampaignItem={setCampaignItem}
        loading={isLoading}
        approvalStatus={approvalStatus}
        setApprovalStatus={setApprovalStatus}
        handleNewsletterFormSubmit={handleSaveNewsAndEvent}
        userAuthLevel={userAuthLevel}
        title={title}
        setTitle={setTitle}
        numberOfEmails={subscriptions.length}
        admin={
          userAuthLevel === "Super Admin" && currentRoute.includes("approvals")
            ? true
            : false
        }
      />
    </DashboardLayout>
  );
};

export default EditNewslettersPage;
