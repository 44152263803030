import React from "react";
import {
  FormButtonsContainer,
  PrimaryButton,
  SecondaryButton,
} from "../shared/Buttons";
import TextEditor from "../shared/TextEditor";
import PostApproval from "../shared/PostApproval";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "../shared/Spinner";
import FormInput from "../shared/FormInput";
import { HelperText, InputLabel } from "../shared/Texts";
import {
  PageForm,
  PageFormContainer,
  PageFormInputContainer,
  PageFormTextEditorContainer,
} from "../shared/PageFormComponents";

const NewslettersForm = ({
  newsletterId,
  loading,
  emails,
  setEmails,
  campaignItem,
  setCampaignItem,
  handleNewsletterFormSubmit,
  title,
  approvalStatus,
  setApprovalStatus,
  setTitle,
  admin,
  numberOfEmails,
}) => {
  const navigate = useNavigate();

  return (
    <PageFormContainer>
      <PageForm onSubmit={handleNewsletterFormSubmit}>
        <PageFormInputContainer>
          <InputLabel>Email List</InputLabel>
          <HelperText>
            Your email will be broadcasted to{" "}
            <Link
              to="/dashboard/newsletters/subscribers"
              style={{
                color: "black",
                opacity: "0.9",
                textDecoration: "none",
                fontWeight: 800,
              }}
            >
              {numberOfEmails} Emails
            </Link>
          </HelperText>
        </PageFormInputContainer>
        <PageFormInputContainer style={{ marginTop: "25px" }}>
          <FormInput
            text="Email Subject"
            type="text"
            value={title}
            name="title"
            handleOnChange={(e) => setTitle(e.target.value)}
            plain={true}
            disabled={admin}
            short={false}
            helperText="Type the subject of the email"
          />
        </PageFormInputContainer>
        <PageFormTextEditorContainer>
          <InputLabel>Newsletter Content</InputLabel>
          <HelperText>Type the content of the email</HelperText>
          <TextEditor
            textData={campaignItem}
            setTextData={setCampaignItem}
            text="campaignItem"
            disabled={admin}
            smallerEditor={false}
          />
        </PageFormTextEditorContainer>
        {newsletterId && admin && (
          <FormButtonsContainer left>
            <PostApproval
              approvalStatus={approvalStatus}
              setApprovalStatus={setApprovalStatus}
            />
          </FormButtonsContainer>
        )}

        <FormButtonsContainer right>
          <SecondaryButton
            onClick={() =>
              admin
                ? navigate("/dashboard/approvals")
                : navigate("/dashboard/newsletters")
            }
            type="button"
            marginRight
          >
            Cancel
          </SecondaryButton>
          {newsletterId && (
            <>
              {admin ? (
                approvalStatus === "approved" ? (
                  <PrimaryButton type="submit">
                    {loading ? (
                      <>
                        <span>Posting Item</span>
                        <Spinner />
                      </>
                    ) : (
                      <span>Post Item</span>
                    )}
                  </PrimaryButton>
                ) : approvalStatus === "not approved" ? (
                  <PrimaryButton type="submit">
                    {loading ? (
                      <>
                        <span>Discarding Item</span>
                        <Spinner />
                      </>
                    ) : (
                      <span>Discard Item</span>
                    )}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton type="submit">
                    {loading ? (
                      <>
                        <span>Saving Changes</span>
                        <Spinner />
                      </>
                    ) : (
                      <span>Save Changes</span>
                    )}
                  </PrimaryButton>
                )
              ) : (
                <PrimaryButton type="submit">
                  {loading ? (
                    <>
                      <span>Saving Changes</span>
                      <Spinner />
                    </>
                  ) : (
                    <span>Save Changes</span>
                  )}
                </PrimaryButton>
              )}
            </>
          )}

          {!newsletterId && (
            <PrimaryButton type="submit">
              {loading ? (
                <>
                  <span>Sending For Approval</span>
                  <Spinner />
                </>
              ) : (
                <span>Send For Approval</span>
              )}
            </PrimaryButton>
          )}
        </FormButtonsContainer>
      </PageForm>
    </PageFormContainer>
  );
};

export default NewslettersForm;
