import { IoClose } from "react-icons/io5";
import styled from "styled-components";
import { SecondaryButton, PrimaryButton } from "./Buttons";
import { Spinner } from "./Spinner";

const Popout = ({
  onClose,
  backgroundWhite = false,
  boldText,
  text,
  secondaryButtonText,
  primaryButtonText,
  primaryButtonAction,
  opened,
  loadingAction,
  primaryLoadingButtonText,
}) => {
  return (
    <>
      <PopoutBackground opened={opened} />
      <PopoutWrapper opened={opened} backgroundWhite={backgroundWhite}>
        <PopoutContainer opened={opened}>
          <PopoutCloseButton type="button" onClick={onClose}>
            <IoClose size={19} />
          </PopoutCloseButton>
          <PopoutContent>
            {boldText && <PopoutBoldText>{boldText}</PopoutBoldText>}
            {text && <PopoutText>{text}</PopoutText>}
            <PopoutButtonsContainer>
              <SecondaryButton onClick={onClose} marginRight>
                {secondaryButtonText}
              </SecondaryButton>
              <PrimaryButton onClick={primaryButtonAction}>
                {loadingAction ? (
                  <>
                    <span>{primaryLoadingButtonText}</span>
                    <Spinner />
                  </>
                ) : (
                  <span>{primaryButtonText}</span>
                )}
              </PrimaryButton>
            </PopoutButtonsContainer>
          </PopoutContent>
        </PopoutContainer>
      </PopoutWrapper>
    </>
  );
};

export default Popout;

const PopoutBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background-color: black;
  opacity: 0.1;
  backdrop-filter: blur(10px);
  z-index: ${(props) => (props.opened ? "100" : "-1000")};
  transition: all 250ms ease-in-out;
  width: 100%;
  height: 100vh;
`;

const PopoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(10px);
  z-index: ${(props) => (props.opened ? "100" : "-1000")};
  opacity: ${(props) => (props.opened ? "1" : "0")};
  transition: all 250ms ease-in-out;
`;

const PopoutContainer = styled.div`
  padding: 15px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.backgroundWhite ? "#FFFFFF" : "#FAFFF4"};
  transform: ${(props) => (props.opened ? "scale(1)" : "scale(0.5)")};
  transition: all 250ms ease-in-out;
`;

const PopoutCloseButton = styled.button`
  float: right;
  padding: 1px;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const PopoutContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
`;

const PopoutBoldText = styled.p`
  font-size: 18px;
  font-weight: 900;
  color: #272937;
  padding-bottom: 10px;
`;

const PopoutText = styled.p`
  font-size: 15px;
  font-weight: 700;
  color: rgba(39, 41, 55, 0.75);
`;

const PopoutButtonsContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
`;
