import axios from "axios";

const jobListingUrl = process.env.REACT_APP_SERVER_JOBLISTING_URL;

// Create new job listing
const createJobListing = async (jobListingData, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.post(jobListingUrl, jobListingData, config);

  return response.data;
};

// Get single job listing
const getJoblisting = async (jobListingId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${jobListingUrl}/${jobListingId}`, config);

  return response.data;
};

// Get all job listing
const getAllJobListings = async (token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.get(jobListingUrl, config);
  const listings = [];
  listings.push(response);
  return response.data;
};

// Update job listing
const updateJobListing = async (jobListingData, jobListingId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
      "content-type": "application/json",
    },
  };
  const response = await axios.put(
    `${jobListingUrl}/${jobListingId}`,
    jobListingData,
    config
  );

  return response.data;
};

// Delete job listing
const deleteJoblisting = async (jobListingId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(
    `${jobListingUrl}/${jobListingId}`,
    config
  );

  return response.data;
};

const jobListingService = {
  createJobListing,
  getJoblisting,
  getAllJobListings,
  updateJobListing,
  deleteJoblisting,
};

export default jobListingService;
