import styled from "styled-components";

export const HelperText = styled.span`
  font-size: 15px;
  font-weight: 500;
  color: black;
  opacity: 0.6;
  padding-bottom: 13px;
`;

export const InputLabel = styled.span`
  font-size: 17px;
  font-weight: 700;
  color: #013a4f;
  padding-bottom: 2px;
`;
