import React, { useEffect, useState } from "react";
import Modal from "../shared/Modal";
import styled from "styled-components";

import { PrimaryButton } from "../shared/Buttons";
import FormInput from "../shared/FormInput";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerUser, reset } from "../../redux/reducers/authSlice";
import { Spinner } from "../shared/Spinner";
import { errorToast, successToast } from "../shared/Toasts";
import { updateUniqueUser } from "../../redux/reducers/adminSlice";

const NewUserModal = ({ onClose, opened, editMode, user }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");

  const [authLevel, setAuthLevel] = useState("Super Admin");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (editMode) {
      if (user !== undefined) {
        setName(user.name);
        setEmail(user.email);
        setPosition(user.position);
        setAuthLevel(user.authLevel);
      }
    }
  }, [editMode, user]);

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const userData = {
      name,
      email,
      position,
      authLevel,
    };
    dispatch(registerUser(userData));
    setTimeout(() => {
      if (!isLoading) {
        reset();
        onClose();
        navigate(0);
      }
    }, 2000);

    setTimeout(() => {
      if (isError) {
        setLoading(false);
        errorToast(message);
      }

      if (isSuccess) {
        setLoading(false);
        successToast("", "Successfully Added New User");
      }
    }, 2000);
  };

  const handleUpdateUser = (e) => {
    e.preventDefault();
    setLoading(true);
    const userData = {
      name,
      email,
      position,
      authLevel,
      _id: user._id,
      isAdmin: true,
    };
    dispatch(updateUniqueUser(userData));
    setTimeout(() => {
      if (!isLoading) {
        onClose();
        navigate(0);
      }
    }, 3000);

    setTimeout(() => {
      if (isError) {
        setLoading(false);
        errorToast(message);
      }

      if (isSuccess) {
        setLoading(false);
        successToast("saved");
      }
    }, 3000);
  };

  return (
    <Modal onClose={onClose} backgroundWhite={true} opened={opened}>
      <NewUserModalContent
        onSubmit={editMode ? handleUpdateUser : handleRegisterSubmit}
      >
        <NewUserModalText>
          {editMode ? "Edit User" : "New User"}
        </NewUserModalText>
        <FormInput
          text="Name"
          inputType="text"
          value={name}
          name="name"
          handleOnChange={(e) => setName(e.target.value)}
        />
        <NewUserModalInputContainer>
          <FormInput
            text="Email"
            inputType="text"
            value={email}
            name="email"
            handleOnChange={(e) => setEmail(e.target.value)}
          />
        </NewUserModalInputContainer>
        <NewUserModalInputContainer>
          <FormInput
            text="Position"
            inputType="text"
            value={position}
            name="position"
            handleOnChange={(e) => setPosition(e.target.value)}
          />
        </NewUserModalInputContainer>
        <NewUserModalInputContainer>
          <FormInput
            text="Authorization Level"
            inputType="dropdown"
            name="authLevel"
            dropdownOptions={["Super Admin", "HR Admin", "Operations Admin"]}
            dropdownValue={authLevel}
            handleOnChange={(e) => setAuthLevel(e.target.value)}
          />
        </NewUserModalInputContainer>

        <PrimaryButton type="submit">
          {loading ? (
            <>
              <span>{editMode ? "Saving Changes" : "Adding User"}</span>
              <Spinner />
            </>
          ) : (
            <span>{editMode ? "Save Changes" : "Add User"}</span>
          )}
        </PrimaryButton>
      </NewUserModalContent>
    </Modal>
  );
};

export default NewUserModal;

const NewUserModalContent = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 35px;
  width: 20rem;
`;

const NewUserModalText = styled.span`
  font-size: 20px;
  font-weight: 700;
  margin-top: 25px;
  padding-bottom: 15px;
  color: #013a4f;
`;

const NewUserModalInputContainer = styled.div`
  width: 100%;
  margin-top: -10px;
`;
