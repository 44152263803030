import React, { useState } from "react";
import {
  DashboardLayout,
  errorToast,
  FormButtonsContainer,
  PrimaryButton,
  Spinner,
  successToast,
  SettingsTab,
  FormInput,
} from "../../components";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../redux/reducers/adminSlice";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoCloseSharp } from "react-icons/io5";

const SettingsPage = ({ userAuthLevel }) => {
  const [profileImageData, setProfileImageData] = useState(null);
  const [savingChanges, setSavingChanges] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.admin
  );
  const [profileImage, setProfileImage] = useState(user.profileImage);

  const [userDetails, setUserDetails] = useState({
    name: user.name,
    email: user.email,
    password: user.password,
    _id: user._id,
    isAdmin: user.isAdmin,
    resetLink: user.resetLink,
    username: user.username,
    authLevel: user.authLevel,
    position: user.position,
    createdAt: user.createdAt,
    updatedAt: new Date().toLocaleString(),
    __v: user.__v,
    accessToken: user.accessToken,
  });
  const { name, email, password, _id, isAdmin, accessToken } = userDetails;

  const userSettingsForm = (e) => {
    setUserDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleUserSettingsSubmit = (e) => {
    e.preventDefault();
    setSavingChanges(true);

    const userData = {
      name,
      profileImage,
      email,
      password,
      _id,
      isAdmin,
      accessToken,
    };
    dispatch(updateUser(userData));
    if (!isLoading) {
      setTimeout(() => {
        if (isError) {
          setSavingChanges(false);
          errorToast(message);
        }

        if (isSuccess) {
          setSavingChanges(false);
          successToast("saved");
          setTimeout(() => {
            navigate(0);
          }, 3000);
        }
      }, 1500);
    }
  };

  const handleProfileImageChange = (changeEvent) => {
    setProfileImage(changeEvent.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(changeEvent.target.files[0]);
    reader.onload = function (onLoadEvent) {
      setProfileImageData(onLoadEvent.target.result);
    };
  };

  return (
    <DashboardLayout title="Admin Settings">
      <GeneralSettingsContainer onSubmit={handleUserSettingsSubmit}>
        {userAuthLevel === "Super Admin" && <SettingsTab />}
        <GeneralSettingsSection
          reduceTopPadding={userAuthLevel !== "Super Admin" ? true : false}
          itemCenter={false}
        >
          <UserProfileDetailsContainer>
            <div>
              <Label>User Profile</Label>
              <SettingDescription>
                Update your profile details{" "}
              </SettingDescription>
            </div>
          </UserProfileDetailsContainer>
          <UserProfileInputsContainer>
            <InputContainer style={{ paddingBottom: "20px" }}>
              <Label>Profile Image</Label>
              <>
                {profileImage ===
                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" ? (
                  <>
                    {profileImageData === null && (
                      <UserProfileImageInput htmlFor="profile-image">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              marginBottom: "8px",
                              fontSize: "16px",
                              display: "inline-flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <AiOutlineCloudUpload
                              style={{ marginRight: "5px" }}
                              size={20}
                            />{" "}
                            Click to upload
                          </span>
                          <p
                            style={{
                              fontSize: "12px",
                              color: "gray",
                              textAlign: "center",
                            }}
                          >
                            Upload your profile image. PNG, SVG or GIF (Maximum
                            file size 10MB)
                          </p>
                        </div>
                        <input
                          id="profile-image"
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleProfileImageChange}
                        />
                      </UserProfileImageInput>
                    )}
                  </>
                ) : (
                  <>
                    {profileImageData !== null && (
                      <>
                        <ProfileImageContainer>
                          <div
                            style={{
                              position: "relative",
                              width: "fit-content",
                            }}
                          >
                            <ProfileImage src={profileImageData} />

                            <ProfileImageRemoveButton
                              onClick={() => {
                                setProfileImageData(null);
                                setProfileImage(
                                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                                );
                              }}
                            />
                          </div>
                        </ProfileImageContainer>
                      </>
                    )}
                    {typeof profileImage === "string" && (
                      <ProfileImageContainer>
                        <div
                          style={{ position: "relative", width: "fit-content" }}
                        >
                          <ProfileImage src={profileImage} />

                          <ProfileImageRemoveButton
                            onClick={() => {
                              setProfileImageData(null);
                              setProfileImage(
                                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                              );
                            }}
                          />
                        </div>
                      </ProfileImageContainer>
                    )}
                  </>
                )}
              </>
            </InputContainer>
            <InputContainer>
              <Label>Name</Label>
              <Input>
                <FormInput
                  inputType="text"
                  value={name}
                  name="name"
                  handleOnChange={userSettingsForm}
                  plain={true}
                />
              </Input>
            </InputContainer>
          </UserProfileInputsContainer>
        </GeneralSettingsSection>
        <GeneralSettingsSection lessBottomPadding>
          <UserSecurityContainer>
            <Label>Security</Label>
            <SettingDescription>
              Update your security details regularly
            </SettingDescription>
          </UserSecurityContainer>
          <UserSecurityInputsContainer>
            <InputContainer>
              <Label>Email</Label>
              <Input>
                <FormInput
                  inputType="email"
                  value={email}
                  name="email"
                  handleOnChange={userSettingsForm}
                  plain={true}
                />
              </Input>
            </InputContainer>
            <InputContainer>
              <Label>Password</Label>
              <Input>
                <FormInput
                  inputType="password"
                  value={password}
                  name="password"
                  handleOnChange={userSettingsForm}
                  plain={true}
                />
              </Input>
            </InputContainer>
          </UserSecurityInputsContainer>
        </GeneralSettingsSection>
        <FormButtonsContainer right>
          <PrimaryButton style={{ marginRight: "35px" }} type="submit">
            {savingChanges ? (
              <>
                <span>Saving Changes</span>
                <Spinner />
              </>
            ) : (
              <span>Save Changes</span>
            )}
          </PrimaryButton>
        </FormButtonsContainer>
      </GeneralSettingsContainer>
    </DashboardLayout>
  );
};

export default SettingsPage;

const GeneralSettingsContainer = styled.form`
  width: 100%;
  margin-top: 15px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const GeneralSettingsSection = styled.div`
  width: 90%;
  border-bottom: 1px solid #95f400;
  display: inline-flex;
  justify-content: start;
  align-items: ${(props) => (props.itemCenter ? "center" : "start")};
  padding-top: ${(props) => (props.reduceTopPadding ? "0" : "60px")};
  padding-bottom: ${(props) => (props.lessBottomPadding ? "70px" : "90px")};
  padding-left: 50px;
  margin-left: 15px;
  margin-top: ${(props) => (props.reduceTopPadding ? "-20px" : "0")};
`;

const UserProfileDetailsContainer = styled.div`
  max-width: 18rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin-right: 19px;
`;

const SettingDescription = styled.p`
  display: block;
  font-size: 14px;
  color: gray;
`;

const UserProfileInputsContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-left: 26.5%;
`;

const InputContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: start;
  &:last-child {
    margin-top: 20px;
  }
`;

const Input = styled.div`
  width: 73%;
  margin-top: -10px;
`;
const UserSecurityContainer = styled.div`
  max-width: 15rem;
`;

const UserSecurityInputsContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-left: 18.5%;
`;

const UserProfileImageInput = styled.label`
  padding: 40px 0;
  border: 1px solid;
  background-color: #ffffff;
  border: 0.05px solid rgba(194, 194, 194, 0.4);
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.1s;
  &:hover {
    background-color: #f5f5f5;
  }
  width: 85%;
`;

const ProfileImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: transparent;
  width: 100%;
  border-radius: 7px;
  margin-left: 6.2%;
`;

const ProfileImage = styled.img`
  border: none;
  width: 12rem;
  height: 12rem;
  border-radius: 7px;
`;

const ProfileImageRemoveButton = styled(IoCloseSharp)`
  position: absolute;
  top: 3%;
  right: 3%;
  background-color: rgba(1, 58, 79, 0.9);
  color: white;
  padding: 3px;
  border-radius: 20%;
  cursor: pointer;
  &:hover {
    background-color: rgba(1, 58, 79, 1);
  }
`;
