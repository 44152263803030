import React, { useState } from "react";
import styled from "styled-components";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import ProfileDropdown from "./ProfileDropdown";
import { useSelector } from "react-redux";
import useOutsideClick from "../../utils/useDetectOutsideClick";
import { useRef } from "react";

const Header = () => {
  const [profileDropdown, setProfileDropdown] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const ref = useRef();

  useOutsideClick(ref, () => {
    setProfileDropdown(false);
  });

  return (
    <HeaderComponent>
      <UserContainer>
        <User
          ref={ref}
          clicked={profileDropdown}
          onClick={() => setProfileDropdown(!profileDropdown)}
        >
          <UserPorfileImage
            src={`${
              user?.profileImage
                ? user.profileImage
                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
            }`}
            alt="Profile Image"
          />
          <UserProfileTextContent>
            <UserTitle>{user?.position}</UserTitle>
            <UserName>
              {user?.name}
              {!profileDropdown && (
                <AiFillCaretDown
                  style={{
                    marginLeft: "5px",
                  }}
                />
              )}
              {profileDropdown && (
                <AiFillCaretUp
                  style={{
                    marginLeft: "5px",
                  }}
                />
              )}
            </UserName>
          </UserProfileTextContent>
        </User>
        <ProfileDropdown opened={profileDropdown} />
      </UserContainer>
    </HeaderComponent>
  );
};

export default Header;

const HeaderComponent = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
`;

const UserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  position: relative;
`;

const User = styled.button`
  text-align: center;
  padding: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  height: 100%;
  transition: all 300ms ease-in-out;
`;

const UserTitle = styled.span`
  color: #232323;
  font-size: 12px;
  opacity: 0.5;
  font-weight: 600;
`;

const UserName = styled.span`
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

const UserPorfileImage = styled.img`
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 8px;
  border-radius: 100%;
`;

const UserProfileTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;
