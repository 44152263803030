import React, { useEffect, useState } from "react";
import {
  errorToast,
  DashboardLayout,
  NewslettersForm,
  successToast,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllSubscriptions } from "../../redux/reducers/subscriptionSlice";
import { createPost } from "../../redux/reducers/newsletterSlice";

const NewNewsletterPage = () => {
  const [name, setName] = useState("");
  const [campaignItem, setCampaignItem] = useState("");
  const [title, setTitle] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.newsletters
  );
  const { subscriptions } = useSelector((state) => state.subscriptions);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(getAllSubscriptions());
  }, [dispatch]);
  useEffect(() => {
    setName(user.name);
  }, [user]);

  const handleNewsletterFormSubmit = (e) => {
    e.preventDefault();

    const newsletterData = {
      name,
      campaignItem,
      title,
      user: user?.name,
    };

    dispatch(createPost(newsletterData));
    if (!isLoading) {
      setTimeout(() => {
        if (isError) {
          errorToast(message);
        }

        if (isSuccess) {
          successToast("created");
          setTimeout(() => {
            navigate("/dashboard/newsletters");
          }, 3000);
        }
      }, 1500);
    }
  };

  return (
    <DashboardLayout title="New Newsletter">
      <NewslettersForm
        primaryBtnName="Send For Approval"
        secondaryBtnName="Discard"
        campaignItem={campaignItem}
        setCampaignItem={setCampaignItem}
        loading={isLoading}
        handleNewsletterFormSubmit={handleNewsletterFormSubmit}
        title={title}
        setTitle={setTitle}
        numberOfEmails={subscriptions.length}
      />
    </DashboardLayout>
  );
};

export default NewNewsletterPage;
