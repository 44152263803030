import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TbTrash, TbEdit } from "react-icons/tb";
import {
  DashboardLayout,
  errorToast,
  Popout,
  successToast,
} from "../../components";
import { TableActionButton, TableStatusLabel } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllApprovalListings,
  removeApprovalListing,
} from "../../redux/reducers/approvalSlice";
import SkeletonListingTable from "../../components/shared/SkeletonListingTable";
import { useLocation, useNavigate } from "react-router-dom";
import useTable from "../../utils/useTablePagination";
import TableFooter from "../../components/shared/TableFooter";
import { deleteJoblisting } from "../../redux/reducers/singleJobListingSlice";
import { deleteNewsletter } from "../../redux/reducers/singleNewsletterSlice";
import { deletePost } from "../../redux/reducers/singleNewsAndEventSlice";

const ApprovalsPage = () => {
  const [deleteItem, setDeleteItem] = useState(null);
  const [page, setPage] = useState(1);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [postType, setPostType] = useState("");

  const navigate = useNavigate();
  const currentPath = useLocation().pathname;
  const heads = ["post", "post type", "status", "actions"];
  const dispatch = useDispatch();
  const { approvalListings, isLoading } = useSelector(
    (state) => state.approvals
  );

  const { slice, range } = useTable(approvalListings, page, 6);

  useEffect(() => {
    dispatch(getAllApprovalListings());
  }, [dispatch]);

  function handleDelete() {
    setLoadingDelete(true);
    if (postType === "job") {
      dispatch(removeApprovalListing(deleteItem));
      dispatch(deleteJoblisting(deleteItem));
      setTimeout(() => {
        setLoadingDelete(false);
        setDeleteItem(null);
        successToast("deleted");
      }, 400);
    }
    if (postType === "event") {
      dispatch(removeApprovalListing(deleteItem));
      dispatch(deletePost(deleteItem));
      setTimeout(() => {
        setLoadingDelete(false);
        setDeleteItem(null);
        successToast("deleted");
      }, 400);
    }
    if (postType === "newsletter") {
      dispatch(removeApprovalListing(deleteItem));
      dispatch(deleteNewsletter(deleteItem));
      setTimeout(() => {
        setLoadingDelete(false);
        setDeleteItem(null);
        successToast("deleted");
      }, 400);
    } else {
      errorToast("Something went wrong");
    }
  }

  return (
    <DashboardLayout title="Approvals">
      {isLoading ? (
        <SkeletonListingTable />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            minHeight: "650px",
          }}
        >
          {approvalListings.length > 0 ? (
            <TableContainer style={{ paddingBottom: "2rem" }}>
              <TableHead>
                <TableHeadRow>
                  {heads.map((head, index) => {
                    return (
                      <TableHeadItem key={index} item={head}>
                        {head}
                      </TableHeadItem>
                    );
                  })}
                </TableHeadRow>
              </TableHead>
              <TableBody>
                {slice.map((d, index) => (
                  <TableRow clickable={false} key={index}>
                    <>
                      <TableDescription
                        item={d.jobRole ? d.jobRole : d.title ? d.title : ""}
                      >
                        {d.jobRole ? d.jobRole : d.title ? d.title : ""}
                      </TableDescription>
                      <TableDescription item={d.jobType}>
                        {d.jobRole
                          ? "Job Listing"
                          : d.authorName
                          ? "News Item"
                          : "Newsletter"}
                      </TableDescription>
                      <TableDescription item={d.status} center>
                        <TableStatusLabel
                          active={d.status === "approved" ? true : false}
                          inactive={d.status === "not approved" ? true : false}
                          pending={d.status === "pending" ? true : false}
                        >
                          {d.status}
                        </TableStatusLabel>
                      </TableDescription>
                    </>
                    <TableDescription center>
                      <TableActionButton
                        onClick={() => {
                          setDeleteItem(d._id);
                          setPostType(
                            d.jobRole
                              ? "job"
                              : d.authorName
                              ? "event"
                              : "newsletter"
                          );
                        }}
                      >
                        <TbTrash size={20} />
                      </TableActionButton>
                      <TableActionButton
                        onClick={() =>
                          navigate(
                            `${currentPath}/${
                              d.jobRole
                                ? "careers"
                                : d.authorName
                                ? "news-and-events"
                                : "newsletters"
                            }/${d._id}`
                          )
                        }
                      >
                        <TbEdit size={20} />
                      </TableActionButton>
                    </TableDescription>
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TableContainer>
                <TableHead>
                  <TableHeadRow>
                    {heads.map((head, index) => {
                      return (
                        <TableHeadItem
                          key={index}
                          item={head}
                          center={
                            (head === "status" ? true : false) ||
                            (head.includes("action") ? true : false)
                          }
                        >
                          {head}
                        </TableHeadItem>
                      );
                    })}
                  </TableHeadRow>
                </TableHead>
              </TableContainer>
              <NoListingsDescription>No Listings</NoListingsDescription>
            </div>
          )}
          {range.length > 1 && (
            <TableFooter
              range={range}
              slice={slice}
              setPage={setPage}
              page={page}
            />
          )}{" "}
        </div>
      )}
      <Popout
        onClose={() => setDeleteItem(false)}
        boldText="ARE YOU SURE?"
        text="You will not be able to undo this action if you proceed!"
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        primaryLoadingButtonText="Deleting"
        opened={deleteItem}
        primaryButtonAction={handleDelete}
        loadingAction={loadingDelete}
      />
    </DashboardLayout>
  );
};

export default ApprovalsPage;

const TableContainer = styled.table`
  width: 100%;
  heught: 100%;
  overflow: sroll;
  border-radius: 50px;
  border-spacing: 0;
`;

const TableHead = styled.thead`
  background-color: white;
  width: 100%;
  border-radius: 50px;
`;

const TableBody = styled.tbody`
  background-color: transparent;
  width: 100%;
`;

const TableHeadRow = styled.tr`
  background-color: transparent;
  width: 100%;
  height: 60px;
  text-align: center;
`;

const TableRow = styled.tr`
  background-color: transparent;
  width: 100%;
  height: 45px;
  border-bottom: 1px solid gray;
`;

const TableHeadItem = styled.th`
  font-size: 14px;
  text-align: left;
  padding: 0 35px;
  color: #013a4f;
  font-weight: 700;
  text-align: center;

  text-transform: uppercase;
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: left;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: left;
  }
`;

const TableDescription = styled.td`
  font-size: 15px;
  padding: 30px 35px;
  font-weight: 500;
  border-bottom: 0.05px solid rgba(194, 194, 194, 0.4);
  min-width: 8rem;
  text-align: center;
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: left;
  }
`;
const NoListingsDescription = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding: 30px 0;
  font-weight: 500;
  border-bottom: 0.05px solid rgba(194, 194, 194, 0.4);
  text-align: center;
`;
