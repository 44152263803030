import styled from "styled-components";
import { CgSpinner } from "react-icons/cg";

export const Spinner = styled(CgSpinner)`
  margin-left: 10px;
  font-size: 17px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
