import React from "react";
import Modal from "./Modal";
import Logo from "../../assets/images/logo-alft.svg";
import styled from "styled-components";

const MobileWarningModal = ({ opened }) => {
  return (
    <Modal mobile={true} opened={opened}>
      <MobileWarningLogo src={Logo} alt="ALFT Logo" />
      <br />
      <MobileWarningText>
        You appear to be using a mobile to access this page. Please switch to a
        laptop/desktop instead.
      </MobileWarningText>
    </Modal>
  );
};

export default MobileWarningModal;
const MobileWarningLogo = styled.img`
  margin-top: 10px;
  width: 68%;
`;

const MobileWarningText = styled.p`
  margin-top: 25px;
  font-size: 18px;
  font-weight: 700;
  color: rgba(39, 41, 55, 0.75);
  text-align: center;
  padding-bottom: 25px;
`;
