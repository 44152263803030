import React from "react";
import styled from "styled-components";
import { RiSettings5Fill, RiArticleFill } from "react-icons/ri";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { HiNewspaper } from "react-icons/hi";
import { IoBriefcase } from "react-icons/io5";
import { MdVerified } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as SidebarLogo } from "../../assets/images/logo-alft.svg";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const [approvals, setApprovals] = useState(false);
  const [careers, setCareers] = useState(false);
  const [newsAndEvents, setNewsAndEvents] = useState(false);
  const [newsletters, setNewsletters] = useState(false);
  const [responses, setResponses] = useState(false);
  const [settings, setSettings] = useState(false);
  const currentRoute = useLocation().pathname;
  const { user } = useSelector((state) => state.auth);
  const userAuthLevel = user?.authLevel;

  const navigate = useNavigate();

  const handleRoute = (to) => {
    navigate(to);
  };

  useEffect(() => {
    if (currentRoute.includes("/dashboard/careers")) {
      setCareers(true);
    } else if (currentRoute.includes("/dashboard/news-and-events")) {
      setNewsAndEvents(true);
    } else if (currentRoute.includes("/dashboard/newsletters")) {
      setNewsletters(true);
    } else if (currentRoute.includes("/dashboard/responses")) {
      setResponses(true);
    } else if (currentRoute.includes("/dashboard/settings")) {
      setSettings(true);
    } else {
      setApprovals(true);
    }
  }, [currentRoute]);

  return (
    <SidebarComponent>
      <SidebarLogoContainer>
        <SidebarLogo style={{ width: "99%" }} />
      </SidebarLogoContainer>
      <SidebarButtonsContainer>
        {userAuthLevel === "Super Admin" && (
          <SidebarButtonContainer>
            <ActiveIndicator active={approvals} />
            <SidebarButton
              onClick={() => handleRoute("/dashboard/approvals")}
              active={approvals}
            >
              <ApprovalsIcon size={27} /> Approvals
            </SidebarButton>
          </SidebarButtonContainer>
        )}
        {userAuthLevel !== "Operations Admin" && (
          <SidebarButtonContainer>
            <ActiveIndicator active={careers} />
            <SidebarButton
              onClick={() => handleRoute("/dashboard/careers")}
              active={careers}
            >
              <CareersIcon size={27} /> Careers
            </SidebarButton>
          </SidebarButtonContainer>
        )}
        {userAuthLevel !== "HR Admin" && (
          <>
            <SidebarButtonContainer>
              <ActiveIndicator active={newsAndEvents} />
              <SidebarButton
                onClick={() => handleRoute("/dashboard/news-and-events")}
                active={newsAndEvents}
              >
                <NewsAndEventsIcon size={27} /> News & Events
              </SidebarButton>
            </SidebarButtonContainer>
            <SidebarButtonContainer>
              <ActiveIndicator active={newsletters} />
              <SidebarButton
                onClick={() => handleRoute("/dashboard/newsletters")}
                active={newsletters}
              >
                <NewslettersIcon size={27} /> Newsletters
              </SidebarButton>
            </SidebarButtonContainer>
            <SidebarButtonContainer>
              <ActiveIndicator active={responses} />
              <SidebarButton
                onClick={() => handleRoute("/dashboard/responses")}
                active={responses}
              >
                <ResponsesIcon size={27} /> Responses
              </SidebarButton>
            </SidebarButtonContainer>
          </>
        )}

        <SidebarButtonContainer>
          <ActiveIndicator active={settings} />
          <SidebarButton
            onClick={() => handleRoute("/dashboard/settings")}
            active={settings}
          >
            <SettingsIcon size={27} /> Settings
          </SidebarButton>
        </SidebarButtonContainer>
      </SidebarButtonsContainer>
    </SidebarComponent>
  );
};

export default Sidebar;

const SidebarComponent = styled.aside`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const SidebarLogoContainer = styled.div`
  padding-top: 66px;
  padding-bottom: 18px;
  padding-left: 38px;
  padding-right: 38px;
`;

const SidebarButtonsContainer = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SidebarButtonContainer = styled.div`
  width: 100%;
  padding: 0;
  display: inline-flex;
  justify-content: start;
  align-items: center;
`;

const SidebarButton = styled.button`
  color: #013a4f;
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
  margin-top: 6px;
  padding-left: ${(props) => (props.active ? "23px" : "30px")};
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  text-size: 28px;
  display: inline-flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
`;

const ActiveIndicator = styled.div`
  width: 7px;
  height: 100%;
  background-color: #013a4f;
  border-radius: 80px;
  display: ${(props) => (props.active ? "block" : "none")};
`;

const ApprovalsIcon = styled(MdVerified)`
  margin-right: 8px;
`;
const CareersIcon = styled(IoBriefcase)`
  margin-right: 8px;
`;
const NewsAndEventsIcon = styled(RiArticleFill)`
  margin-right: 8px;
`;
const NewslettersIcon = styled(HiNewspaper)`
  margin-right: 8px;
`;
const ResponsesIcon = styled(BsFillChatLeftTextFill)`
  margin-right: 8px;
`;
const SettingsIcon = styled(RiSettings5Fill)`
  margin-right: 8px;
`;
