import React, { useEffect, useState } from "react";
import Modal from "../shared/Modal";
import styled from "styled-components";
import { ReactComponent as ResetModalLogo } from "../../assets/images/logo-alft.svg";

import { PrimaryButton } from "../shared/Buttons";
import FormInput from "../shared/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../redux/reducers/authSlice";
import { Spinner } from "../shared/Spinner";
import { errorToast, successToast } from "../shared/Toasts";

const ResetModal = ({ onClose, opened }) => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );
  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email,
    };
    dispatch(forgotPassword(userData));
  };

  useEffect(() => {
    if (isError) {
      errorToast(message);
    }
    if (isSuccess) {
      successToast(
        "",
        "An Email Containing The Password Reset Link Has Been Sent To The Email Address Associated With Your Account."
      );
    }
  }, [isSuccess, isError, message]);

  return (
    <Modal opened={opened} onClose={onClose}>
      <ResetModalContent onSubmit={handleForgotPasswordSubmit}>
        <ResetModalLogo style={{ width: "61%" }} />
        <ResetModalText>
          Enter the email associated with your account
        </ResetModalText>
        <FormInput
          backgroundWhite={true}
          text="Email"
          inputType="email"
          value={email}
          name="email"
          handleOnChange={(e) => setEmail(e.target.value)}
        />
        <PrimaryButton type="submit">
          {!isLoading && <span>Send Reset Link</span>}
          {isLoading && (
            <>
              <span>Sending Reset Link</span> <Spinner />
            </>
          )}
        </PrimaryButton>
      </ResetModalContent>
    </Modal>
  );
};

export default ResetModal;

const ResetModalContent = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 35px;
`;

const ResetModalText = styled.span`
  font-size: 18px;
  font-weight: 700;
  margin-top: 40px;
  padding-bottom: 15px;
`;
