import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jobListingService from "../services/jobListingService";

const initialState = {
  jobListings: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Edit job listing
export const updateJobListing = createAsyncThunk(
  `singleJobListing/edit`,
  async (jobListingData, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.accessToken;
    const data = jobListingData.jobRole
      ? {
          jobRole: jobListingData.jobRole,
          jobType: jobListingData.jobType,
          jobRequirements: jobListingData.jobRequirements,
          department: jobListingData.department,
          status: jobListingData.approvalStatus,
          userName: jobListingData.userName,
        }
      : { isActive: jobListingData.activeStatus };
    try {
      return await jobListingService.updateJobListing(
        data,
        jobListingData.jobListingId,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete a job listing
export const deleteJoblisting = createAsyncThunk(
  "singleJobListing/delete",
  async (jobListingId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await jobListingService.deleteJoblisting(jobListingId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const singleJobListingSlice = createSlice({
  name: "singleJobListing",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateJobListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateJobListing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.jobListings = action.payload;
      })
      .addCase(updateJobListing.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteJoblisting.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteJoblisting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(deleteJoblisting.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = singleJobListingSlice.actions;
export default singleJobListingSlice.reducer;
