import React, { useState } from "react";
import styled from "styled-components";
import {
  FormButtonsContainer,
  PrimaryButton,
  SecondaryButton,
} from "../shared/Buttons";
import TextEditor from "../shared/TextEditor";
import { IoImage, IoCloseSharp } from "react-icons/io5";
import FormInput from "../shared/FormInput";
import PostApproval from "../shared/PostApproval";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../shared/Spinner";
import { HelperText, InputLabel } from "../shared/Texts";
import {
  PageForm,
  PageFormContainer,
  PageFormInlineInputs,
  PageFormInputContainer,
  PageFormTextEditorContainer,
} from "../shared/PageFormComponents";

const NewsAndEventsForm = ({
  handleNewsAndEventFormSubmit,
  newsAndEventId,
  loading,
  title,
  setTitle,
  authorName,
  setAuthorName,
  newsItem,
  setNewsItem,
  coverImage,
  setCoverImage,
  admin,
  approvalStatus,
  setApprovalStatus,
  category,
  setCategory,
}) => {
  const [coverImageData, setCoverImageData] = useState(null);
  const [validatingImage, setValidatingImage] = useState("");
  const navigate = useNavigate();
  const handleCoverImageChange = (changeEvent) => {
    setCoverImage(changeEvent.target.files[0]);
    setValidatingImage(changeEvent.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(changeEvent.target.files[0]);
    reader.onload = function (onLoadEvent) {
      setCoverImageData(onLoadEvent.target.result);
    };
  };
  return (
    <PageFormContainer>
      <PageForm onSubmit={handleNewsAndEventFormSubmit}>
        <PageFormInlineInputs>
          <PageFormInputContainer>
            <FormInput
              text="Title"
              type="text"
              value={title}
              name="title"
              handleOnChange={(e) => setTitle(e.target.value)}
              plain={true}
              short={true}
              disabled={admin}
              helperText="Type the title of the News Item"
            />
          </PageFormInputContainer>
          <PageFormInputContainer>
            <FormInput
              text="Author Name"
              type="text"
              value={authorName}
              name="authorName"
              handleOnChange={(e) => setAuthorName(e.target.value)}
              plain={true}
              disabled={admin}
              short={true}
              helperText="Mention the name of the writer"
            />
          </PageFormInputContainer>
        </PageFormInlineInputs>
        <PageFormInputContainer style={{ width: "50%" }}>
          <FormInput
            text="Select Category"
            inputType="dropdown"
            plain={true}
            short={true}
            name="category"
            disabled={admin}
            dropdownOptions={["News", "Events", "CSR Project"]}
            dropdownValue={category}
            handleOnChange={(e) => setCategory(e.target.value)}
            helperText="Select the category of the article"
          />
        </PageFormInputContainer>

        <PageFormTextEditorContainer>
          <InputLabel>News Item</InputLabel>
          <HelperText>
            Type the content of the News item you want to publish
          </HelperText>
          <TextEditor
            textData={newsItem}
            setTextData={setNewsItem}
            text="newsItem"
            smallerEditor={false}
            disabled={admin}
          />
        </PageFormTextEditorContainer>
        <CoverImageInputContainer
          imageSelected={coverImageData === null ? false : true}
        >
          {newsAndEventId ? (
            <>
              {coverImage === "string" ? (
                <>
                  {coverImageData === null && (
                    <div style={{ position: "relative", width: "100%" }}>
                      <IoImage style={{ color: "#013a4f" }} />
                      <CoverImageInputButton htmlFor="files">
                        Choose Cover Image
                      </CoverImageInputButton>
                      <input
                        style={{
                          position: "absolute",
                          left: "1%",
                          top: "1%",
                          zIndex: "100",
                          border: "none",
                          background: "transparent",
                          color: "transparent",
                          outline: "none",
                        }}
                        value={validatingImage}
                        required
                      />

                      <CoverImageInput
                        id="files"
                        style={{ visibility: "hidden" }}
                        type="file"
                        onChange={handleCoverImageChange}
                      />
                    </div>
                  )}
                  {coverImageData !== null && (
                    <>
                      <InputLabel>Cover Image</InputLabel>
                      <CoverImageContainer>
                        <CoverImage src={coverImageData} />
                        <CoverImageRemoveButton
                          onClick={() => {
                            setCoverImageData(null);
                            setValidatingImage("");
                          }}
                        />
                      </CoverImageContainer>
                    </>
                  )}
                </>
              ) : (
                <>
                  <InputLabel>Cover Image</InputLabel>
                  <CoverImageContainer>
                    <CoverImage src={coverImage} />
                    <CoverImageRemoveButton
                      onClick={() => {
                        setCoverImage("string");
                        setValidatingImage("");
                      }}
                    />
                  </CoverImageContainer>
                </>
              )}
            </>
          ) : (
            <>
              {coverImageData === null && (
                <div style={{ position: "relative", width: "100%" }}>
                  <IoImage style={{ color: "#013a4f" }} />
                  <CoverImageInputButton htmlFor="files">
                    Choose Cover Image
                  </CoverImageInputButton>
                  <input
                    style={{
                      position: "absolute",
                      left: "1%",
                      top: "1%",
                      zIndex: "100",
                      border: "none",
                      background: "transparent",
                      color: "transparent",
                      outline: "none",
                    }}
                    value={validatingImage}
                    required
                  />

                  <CoverImageInput
                    id="files"
                    style={{ visibility: "hidden" }}
                    type="file"
                    onChange={handleCoverImageChange}
                  />
                </div>
              )}
              {coverImageData !== null && (
                <>
                  <InputLabel>Cover Image</InputLabel>
                  <CoverImageContainer>
                    <CoverImage src={coverImageData} />
                    <CoverImageRemoveButton
                      onClick={() => {
                        setCoverImageData(null);
                        setValidatingImage("");
                      }}
                    />
                  </CoverImageContainer>
                </>
              )}
            </>
          )}
        </CoverImageInputContainer>
        {newsAndEventId && admin && (
          <FormButtonsContainer left>
            <PostApproval
              approvalStatus={approvalStatus}
              setApprovalStatus={setApprovalStatus}
            />
          </FormButtonsContainer>
        )}

        <FormButtonsContainer right>
          <SecondaryButton
            onClick={() =>
              admin
                ? navigate("/dashboard/approvals")
                : navigate("/dashboard/news-and-events")
            }
            type="button"
            marginRight
          >
            Cancel
          </SecondaryButton>
          {newsAndEventId && (
            <>
              {admin ? (
                approvalStatus === "approved" ? (
                  <PrimaryButton type="submit">
                    {loading ? (
                      <>
                        <span>Posting Item</span>
                        <Spinner />
                      </>
                    ) : (
                      <span>Post Item</span>
                    )}
                  </PrimaryButton>
                ) : approvalStatus === "not approved" ? (
                  <PrimaryButton type="submit">
                    {loading ? (
                      <>
                        <span>Discarding Item</span>
                        <Spinner />
                      </>
                    ) : (
                      <span>Discard Item</span>
                    )}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton type="submit">
                    {loading ? (
                      <>
                        <span>Saving Changes</span>
                        <Spinner />
                      </>
                    ) : (
                      <span>Save Changes</span>
                    )}
                  </PrimaryButton>
                )
              ) : (
                <PrimaryButton type="submit">
                  {loading ? (
                    <>
                      <span>Saving Changes</span>
                      <Spinner />
                    </>
                  ) : (
                    <span>Save Changes</span>
                  )}
                </PrimaryButton>
              )}
            </>
          )}

          {!newsAndEventId && (
            <PrimaryButton type="submit">
              {loading ? (
                <>
                  <span>Sending For Approval</span>
                  <Spinner />
                </>
              ) : (
                <span>Send For Approval</span>
              )}
            </PrimaryButton>
          )}
        </FormButtonsContainer>
      </PageForm>
    </PageFormContainer>
  );
};

export default NewsAndEventsForm;

const CoverImageInputContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: ${(props) => (props.imageSelected ? "between" : "start")};
  align-items: ${(props) => (props.imageSelected ? "start" : "center")};
  font-size: 17px;
  margin-top: 25px;
`;

const CoverImageInputButton = styled.label`
  font-weight: 700;
  margin-left: 5px;
  padding-bottom: 1px;
  border-bottom: 1px solid #013a4f;
  color: #013a4f;

  &:hover {
    cursor: pointer;
  }
`;

const CoverImageInput = styled.input`
  padding: 2px;
  background: transparent;
  border: none;
  color: #013a4f;
`;

const CoverImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 7.6%;
`;

const CoverImage = styled.img`
  border-radius: 7px;
  border: none;
  width: 38%;
  height: 100%;
`;

const CoverImageRemoveButton = styled(IoCloseSharp)`
  position: absolute;
  top: 3%;
  left: 34%;
  background-color: rgba(1, 58, 79, 0.9);
  color: white;
  padding: 3px;
  border-radius: 20%;
  cursor: pointer;
  &:hover {
    background-color: rgba(1, 58, 79, 1);
  }
`;
