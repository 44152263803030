import axios from "axios";

const newsAndEventUrl = process.env.REACT_APP_SERVER_NEWSANDEVENTS_URL;

// Create new news and event
const createPost = async (newsAndEventData, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.post(newsAndEventUrl, newsAndEventData, config);

  return response.data;
};

// Get single news and event
const getPost = async (newsAndEventId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${newsAndEventUrl}/${newsAndEventId}`,
    config
  );

  return response.data;
};

// Get all news and events
const getAllPosts = async (token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.get(newsAndEventUrl, config);

  return response.data;
};

// Update news and event
const updatePost = async (newsAndEventData, newsAndEventId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${newsAndEventUrl}/${newsAndEventId}`,
    newsAndEventData,
    config
  );

  return response.data;
};

// Delete news and event
const deletePost = async (newsAndEventId, token) => {
  const config = {
    headers: {
      token: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(
    `${newsAndEventUrl}/${newsAndEventId}`,
    config
  );

  return response.data;
};

const newsAndEventService = {
  createPost,
  getPost,
  getAllPosts,
  updatePost,
  deletePost,
};

export default newsAndEventService;
