import { IoClose } from "react-icons/io5";
import styled from "styled-components";

const Modal = ({
  children,
  onClose,
  backgroundWhite = false,
  opened,
  mobile,
}) => {
  return (
    <>
      <ModalBackground mobile={mobile} opened={opened} />
      <ModalWrapper opened={opened}>
        <ModalContainer
          mobile={mobile}
          opened={opened}
          backgroundWhite={backgroundWhite}
        >
          {onClose && (
            <ModalCloseButton type="button" onClick={onClose}>
              <IoClose size={19} />
            </ModalCloseButton>
          )}
          <ModalContent>{children}</ModalContent>
        </ModalContainer>
      </ModalWrapper>
    </>
  );
};

export default Modal;

const ModalBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background-color: ${(props) => (props.mobile ? "#FAFFF4" : "black")};
  opacity: ${(props) => (props.mobile ? "1" : "0.1")};
  backdrop-filter: ${(props) => (props.mobile ? "" : "blur(10px)")};
  z-index: ${(props) => (props.opened ? "100" : "-1000")};
  transition: all 250ms ease-in-out;
`;

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  backdrop-filter: blur(10px);
  z-index: ${(props) => (props.opened ? "100" : "-1000")};
  opacity: ${(props) => (props.opened ? "1" : "0")};
  transition: all 250ms ease-in-out;
`;

const ModalContainer = styled.div`
  padding: 15px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.backgroundWhite ? "#FFFFFF" : "#FAFFF4"};
  transform: ${(props) => (props.opened ? "scale(1)" : "scale(0.5)")};
  transition: all 250ms ease-in-out;
  width: ${(props) => (props.mobile ? "80%" : "")};
`;

const ModalCloseButton = styled.button`
  float: right;
  padding: 1px;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const ModalContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;
