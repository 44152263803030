import React, { useEffect, useState } from "react";
import { DashboardLayout, ListingsTable } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { getAllResponses } from "../../redux/reducers/responseSlice";

const ResponsesPage = () => {
  const dispatch = useDispatch();
  const [deleteItem, setDeleteItem] = useState(null);

  const { responses, isLoading } = useSelector((state) => state.responses);

  useEffect(() => {
    dispatch(getAllResponses());
  }, [dispatch]);

  const handleResponseFilter = (selectedFilter) => {
    dispatch(getAllResponses(selectedFilter));
  };

  const heads = ["response date", "name", "email", "status"];
  return (
    <DashboardLayout
      title="Responses"
      dropdown={{
        items: ["All Status", "Unopened", "Opened", "Responded"],
        action: handleResponseFilter,
        defaultValue: "Filter By Status",
      }}
    >
      <ListingsTable
        heads={heads}
        tableData={responses}
        type="responses"
        deleteItem={deleteItem}
        setDeleteItem={setDeleteItem}
        loading={isLoading}
      />
    </DashboardLayout>
  );
};

export default ResponsesPage;
