import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const SettingsTab = () => {
  const [generalSettings, setGeneralSettings] = useState(false);
  const [usersSettings, setUsersSettings] = useState(false);
  const currentRoute = useLocation().pathname;

  const navigate = useNavigate();

  const handleRoute = (to) => {
    navigate(to);
  };

  useEffect(() => {
    if (currentRoute.includes("/dashboard/settings/users")) {
      setUsersSettings(true);
    } else {
      setGeneralSettings(true);
    }
  }, [currentRoute]);

  return (
    <div style={{ width: "96%", margin: "0 auto" }}>
      <SettingsTabsContainer>
        <SettingsTabItem
          active={generalSettings}
          onClick={(e) => {
            e.preventDefault();
            handleRoute("/dashboard/settings");
          }}
        >
          General
        </SettingsTabItem>
        <SettingsTabItem
          active={usersSettings}
          onClick={(e) => {
            e.preventDefault();
            handleRoute("/dashboard/settings/users");
          }}
        >
          Users
        </SettingsTabItem>
      </SettingsTabsContainer>
      <hr style={{ borderTop: "0.5px solid #95f400", marginTop: "-2px" }} />
    </div>
  );
};

export default SettingsTab;

const SettingsTabsContainer = styled.div`
  width: 93%;
  display: inline-flex;
  justify-content: start;
  align-items: start;
  padding: 0 10px;
  margin-left: 15px;
`;

const SettingsTabItem = styled.button`
  color: black;
  border: none;
  background: transparent;
  font-size: 20px;
  font-weight: 700;
  margin-left: 30px;
  padding-bottom: 10px;
  border-bottom: ${(props) => (props.active ? "3px solid #013a4f" : "none")};
  &:last-child {
    margin-left: 90px;
  }
  &:hover {
    cursor: pointer;
  }
`;
