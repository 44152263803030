import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jobListingService from "../services/jobListingService";
import newsAndEventService from "../services/newsAndEventService";
import newsletterService from "../services/newsletterService";

const initialState = {
  approvalListings: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getAllApprovalListings = createAsyncThunk(
  "approval-listings",
  async (_, thunkAPI) => {
    try {
      const listings = [];
      const token = thunkAPI.getState().auth.user.accessToken;
      const jobs = await jobListingService.getAllJobListings(token);
      const events = await newsAndEventService.getAllPosts(token);
      const news = await newsletterService.getAllPosts(token);
      const notApprovedJobs = jobs.filter((j) => j.status !== "approved");
      const notApprovedEvents = events.filter((e) => e.status !== "approved");
      const notApprovedNews = news.filter((n) => n.status !== "approved");
      notApprovedJobs.map((j) => {
        return listings.push(j);
      });
      notApprovedEvents.map((e) => {
        return listings.push(e);
      });
      notApprovedNews.map((n) => {
        return listings.push(n);
      });
      const sortedListings = listings.sort((a, b) =>
        a.createdAt.localeCompare(b.createdAt)
      );
      return sortedListings;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const approvalSlice = createSlice({
  name: "approvals",
  initialState,
  reducers: {
    reset: (state) => initialState,
    removeApprovalListing: (state, action) => {
      state.approvalListings = state.approvalListings.filter(
        (a) => a._id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllApprovalListings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllApprovalListings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.approvalListings = action.payload;
      })
      .addCase(getAllApprovalListings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});
export const { reset, removeApprovalListing } = approvalSlice.actions;

export default approvalSlice.reducer;
