import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import adminService from "../services/adminService";

// Get user from sessionStorage
const user = JSON.parse(sessionStorage.getItem("user"));

const initialState = {
  user: user ? user : null,
  users: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Edit User settings
export const updateUser = createAsyncThunk(
  `admin/${user ? user._id : ""}`,
  async (user, thunkAPI) => {
    const userSettings = new FormData();
    userSettings.append("name", user.name);
    userSettings.append("email", user.email);
    userSettings.append("password", user.password);
    userSettings.append("profileImage", user.profileImage);
    userSettings.append("isAdmin", user.isAdmin);

    const userLastActive = new FormData();
    userLastActive.append("lastActive", user.lastActive);
    userLastActive.append("isAdmin", user.isAdmin);
    const data = user.lastActive ? userLastActive : userSettings;

    try {
      return await adminService.updateAdmin(data, user._id, user.accessToken);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUniqueUser = createAsyncThunk(
  `admin/unique-user`,
  async (user, thunkAPI) => {
    const data = new FormData();
    data.append("name", user.name);
    data.append("email", user.email);
    data.append("position", user.position);
    data.append("authLevel", user.authLevel);
    data.append("isAdmin", user.isAdmin);
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await adminService.updateAdmin(data, user._id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllUsers = createAsyncThunk("admin", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.accessToken;
    return await adminService.getAllAdmins(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteUser = createAsyncThunk(
  "admin/delete",
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.accessToken;
      return await adminService.deleteAdmin(userId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.users = [];
    },
    addUser: (state, action) => {
      state.users.push(action.payload);
    },
    removeUser: (state, action) => {
      state.users = state.users.filter((u) => u._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        sessionStorage.setItem("user", JSON.stringify(action.payload));
        state.user = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateUniqueUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUniqueUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateUniqueUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, removeUser, addUser } = adminSlice.actions;

export default adminSlice.reducer;
