import { BsCircleFill } from "react-icons/bs";
import styled from "styled-components";

export const PrimaryButton = styled.button`
  padding: 13px 29px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #95f400;
  border: 2.9px solid #95f400;
  border-radius: 6px;
  color: #013a4f;
  font-weight: 800;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.13s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`;

export const SecondaryButton = styled.button`
  padding: 13px 29px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2.9px solid #95f400;
  border-radius: 6px;
  color: #013a4f;
  background: transparent;
  font-weight: 800;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: ${(props) => (props.marginRight ? "15px" : "0")};
  transition: all 0.13s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`;

export const TableActionButton = styled.button`
  color: #013a4f;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 16px;
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`;

export const TableActionToggle = ({ checked, handleOnSlide }) => {
  return (
    <>
      <TableToggle clicked={checked} onClick={handleOnSlide}>
        <BsCircleFill size={14} />
      </TableToggle>
    </>
  );
};

export const TableToggle = styled.button`
  width: 48px;
  background-color: ${(props) => (props.clicked ? "#95f400" : "#013a4f")};
  border-radius: 32px;
  border: none;
  padding: 4px;

  display: inline-flex;
  justify-content: ${(props) => (props.clicked ? "end" : "start")};
  align-items: center;
  color: white;
  transtion: all 0.5s ease-out;
  cursor: pointer;
`;

export const TableStatusLabel = styled.span`
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  ${(props) => {
    if (props.approved || props.active || props.responded) {
      return `
        background: #80F576;
        color: #118107;
    `;
    } else if (props.pending || props.opened) {
      return `
        background: #FDF290;
        color: #989B0E;
    `;
    } else if (
      props.notApproved ||
      props.inactive ||
      props.unopened ||
      props.remove
    ) {
      return `
        background: #FA9191;
        color: #C00000;
    `;
    } else {
      return `
        background: gray;
        color: black;
    `;
    }
  }}
`;

export const FormButtonsContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  width: 100%;
  display: inline-flex;
  justify-content: ${(props) => (props.left ? "start" : "end")};
  align-items: center;
  &:first-child {
    margin-right: 15px;
  }
`;
